import React, { Component } from "react";
import { Icon } from "antd";
import { WrappedResetPasswordForm } from "./resetPasswordForm";
import "./loginPage.css";
import moment from "moment";
export default class resetPasswordPage extends Component {
  render() {
    return (
      <div className="loginContainer">
        <div className="loginLeft"></div>
        <div className="loginRight">
          <div className="logoHeader">Connect UKB</div>
          <div className="loginForm">
            <WrappedResetPasswordForm keySearch={this.props.location.search} />
          </div>
          <div className="footer">
            <div style={{ textAlign: "center" }}>
              Copyright <Icon type="copyright" /> {moment().format("YYYY")} UKB
              Bagrodia
            </div>
          </div>
        </div>
      </div>
    );
  }
}
