import React, { Component } from 'react'

import { Mutation } from 'react-apollo';
import { UPDATE_TICKET } from '../../../services/graphql/Mutations/'
import { TICKET_QUERY } from '../../../services/graphql/Queries/'

import {
  Row,
  Col,
  Table,
  Tooltip,
  Button,
  Input,
  Icon,
  Tag,
  Spin,
  Divider
} from 'antd';
import AutoScrollWindow from '../../util/AutoScrollWindow';

import moment from 'moment';

import { ResizeableTitle } from '../../util/ResizableTable'
export default class TicketHistoryList extends Component {

  constructor(props) {
    super(props)

    this.state = {
      rowClicked: 0,
      pageSize: 5,
      searchParameter: '',
      reloadSpinner: false,
      selectedRowKeys: [],
      selectedTickets: [],
      columns: [
        {
          title: 'Ticket ID',
          dataIndex: 'ticket_id',
          sorter: (a, b) => moment(a.ticket_created_at).isAfter(b.ticket_created_at) ? 1 : -1,
          defaultSortOrder: 'descend',
        },
        {
          width: 280,
          title: 'Customer Name',
          dataIndex: 'user',
          filters: this.props.users.map(data => {
            return {
              text: `${data.name} #${data.id}`,
              value: data.id
            }
          }),
          onFilter: (value, record) => record.user_id === value
        },
        {
          width: 130,
          title: 'Category',
          dataIndex: 'ticket_category',
          filters: this.props.categories.map(data => {
            return {
              text: data,
              value: data
            }
          }),
          onFilter: (value, record) => record.ticket_category === value
        },
        {
          width: 130,
          title: 'Sub-Category',
          dataIndex: 'ticket_subcategory',
          filters: this.props.subCategories.map(data => {
            return {
              text: data,
              value: data
            }
          }),
          onFilter: (value, record) => record.ticket_subcategory === value
        },
        {
          width: 210,
          title: 'Date Created',
          dataIndex: 'ticket_created_at',
          sorter: (a, b) => moment(a.ticket_created_at).isBefore(b.ticket_created_at) ? 1 : -1,
          defaultSortOrder: 'descend',
        },
        {
          width: 100,
          dataIndex: 'ticket_is_replied',
          render: (text, record) => {
            // console.log('record: ', record.ticket_is_replied);
            return (
              <div>
                {
                  record.ticket_is_closed === 1 ?
                    <Tag style={{ width: '100%', textAlign: 'center' }} color={'volcano'}>
                      CLOSED
                  </Tag>
                    :
                    <Tag style={{ width: '100%', textAlign: 'center' }} color={'geekblue'}>
                      REPLIED
                  </Tag>
                }

              </div>
            )
          },
          filters: [
            {
              text: 'Replied',
              value: 1
            },
            {
              text: 'Closed',
              value: 0
            },
          ],
          onFilter: (value, record) => {
            if (value === 1)
              return record.ticket_is_replied === 1 && record.ticket_is_closed === 0
            return record.ticket_is_closed === 1
          }
        },
        {
          width: 60,
          key: "action",
          // filters: [
          //   {
          //     text: "Replied",
          //     value: 1
          //   },
          //   {
          //     text: "Pending",
          //     value: 0
          //   }
          // ],
          // onFilter: (value, record) => record.ticket_is_replied === value,
          render: (text, record) => {
            if (record.ticket_is_closed === 0)
              return (
                <Mutation
                  mutation={UPDATE_TICKET}
                  variables={{
                    ticket_id: record.ticket_id,
                    ticket_is_closed: 1
                  }}
                  refetchQueries={() => {
                    return [{ query: TICKET_QUERY }];
                  }}
                  onCompleted={data => {
                    record.stopSpinner();
                  }}
                  onError={err => {
                    console.log("mutation err: ", err);
                    record.stopSpinner();
                  }}
                  update={(store, { data }) => {
                    const oldTicketList = store.readQuery({
                      query: TICKET_QUERY
                    }).tickets
                    const newTicketList = oldTicketList.map((value) => {
                      if (value.ticket_id === record.ticket_id)
                        return {
                          ...value,
                          ticket_is_closed: 1
                        }
                      return value
                    })
                    store.writeQuery({ query: TICKET_QUERY, data: { tickets: newTicketList } })

                  }}
                >
                  {(postMutation, { loading, error, data }) => {
                    return (

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip
                          placement="topLeft"
                          title="Mark as closed"
                        >
                          <Button
                            type="primary"
                            onClick={(event) => {
                              event.stopPropagation();
                              record.startSpinner();
                              postMutation();
                            }}
                            icon="close-circle"
                          />
                        </Tooltip>
                      </div>
                    );
                  }}
                </Mutation>
              )
          }
        }
      ]
    }

  }

  handleResize = index => (e, { size }) => {
    console.log("handle resize")
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      return { columns: nextColumns }
    })
  }

  components = {
    header: {
      cell: ResizeableTitle,
    },
  }

  stopSpinner = () => {
    this.setState({
      ...this.state,
      reloadSpinner: false
    });
  };
  startSpinner = () => {
    this.setState({
      ...this.state,
      reloadSpinner: true
    });
  };


  componentDidMount() {
    setTimeout(() => this.updatePageSize(), 100)
    window.addEventListener("resize", this.updatePageSize);
  }

  updatePageSize = () => {
    const containerHeight = window.innerHeight - 160;
    let pageSize = Math.floor(containerHeight / 60);
    pageSize = pageSize <= 0 ? 1 : pageSize;
    this.setState({ pageSize })
  }

  handleRowClick = (key) => {
    console.log(key, "hello")
    this.setState({ ...this.state, rowClicked: key })
  }

  onSelectChange = (selectedRowKeys, record) => {
    this.setState({
      ...this.state,
      selectedRowKeys,
      selectedTickets: record
    });
  };

  markAsClosed = postMutation => {
    const { selectedTickets } = this.state;
    selectedTickets.map(data => {
      if (data.ticket_is_closed === 0)
        postMutation({ ticket_id: data.ticket_id, ticket_is_closed: 1 });
    });
  };

  resetCheckBox = () => {
    this.setState({
      ...this.state,
      selectedRowKeys: [],
      selectedTickets: []
    });
  };

  render() {
    const { pageSize, searchParameter, reloadSpinner, selectedTickets, selectedRowKeys } = this.state
    const { dataSource, selectHistoryTicket, selectedTicketID } = this.props
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index),
        onResizeStart: this.onResizeStart,
        onResizeStop: this.onResizeStop
      }),
    }))
    return (
      <Mutation
        mutation={UPDATE_TICKET}
        refetchQueries={() => {
          return [{ query: TICKET_QUERY }];
        }}
        onCompleted={data => {
          this.stopSpinner();
        }}
        onError={err => {
          console.log("mutation err: ", err);
          this.stopSpinner();
        }}
      >
        {(postMutation, { loading, error, data }) => {
          return (
            <Spin spinning={loading || reloadSpinner}>
              <Row
                type="flex"
                style={{
                  padding: '20px',
                  borderRight: 'solid 1px #d9d9d9',
                }}
                justify="space-between"
              >
                <Col span={10}>
                  <Input
                    prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Search by Ticket ID or Customer Name"
                    allowClear
                    onChange={(event) => {
                      this.setState({
                        ...this.state,
                        searchParameter: event.target.value
                      })
                    }}
                  />
                </Col>
                <Tooltip title={!(selectedTickets.filter(data => data.ticket_is_closed === 1).length === 0 && selectedTickets.length > 0) ? "No tickets selected" : "Mark as replied"}>
                  <Button
                    onClick={() => {
                      this.startSpinner();
                      this.markAsClosed(variables =>
                        postMutation({ variables })
                      );
                    }}
                    disabled={!(selectedTickets.filter(data => data.ticket_is_closed === 1).length === 0 && selectedTickets.length > 0)}
                    style={{ marginRight: '10px' }}
                    type="primary"
                    icon="close-circle"
                  />
                </Tooltip>
              </Row>
              <AutoScrollWindow offset={180}>
                <Table
                  bordered
                  rowSelection={rowSelection}
                  rowKey={record => record.ticket_id}
                  rowClassName={record => "ticketRow " + (selectedTicketID === record.ticket_id ? 'ddd' : '')}
                  // onRow={(record) => ({
                  //   onClick: () => selectHistoryTicket("selectedTicketHistoryID", record.ticket_id)
                  // })}
                  components={this.components}
                  dataSource={dataSource
                    .filter(({ ticket_id, ticket_created_by }) => {
                      const { firstname, lastname } = ticket_created_by
                      return ticket_id.toUpperCase().indexOf(searchParameter.toUpperCase()) > -1 ||
                        `${firstname} ${lastname}`.toUpperCase().indexOf(searchParameter.toUpperCase()) > -1
                    })
                    .map(({ ticket_id, ticket_created_by, ticket_is_replied, ticket_subcategory, ticket_category, ticket_created_at, ticket_is_closed, ticket_description }) => {
                      const { firstname, lastname, user_id } = ticket_created_by
                      return {
                        ticket_id,
                        user_id,
                        user: `${firstname} ${lastname}`,
                        ticket_category: ticket_category.ticket_category_name,
                        ticket_subcategory: ticket_subcategory ? ticket_subcategory.ticket_sub_category_name : '-',
                        ticket_created_at: moment(parseInt(ticket_created_at)).format("LLL"),
                        ticket_is_replied,
                        ticket_is_closed,
                        ticket_description,
                        action: () => selectHistoryTicket("selectedTicketHistoryID", ticket_id),
                        stopSpinner: () => this.stopSpinner(),
                        startSpinner: () => this.startSpinner()
                      }
                    })}
                  columns={columns}
                  pagination={{ pageSize }}
                  size="small"
                  expandRowByClick
                  expandedRowRender={record => (
                    <div>
                      <Divider orientation="left" style={{ fontSize: "small" }}>Ticket Description <Icon type="file-text" theme="filled" /></Divider>
                      {
                        record.ticket_description ? <p style={{ margin: 0, fontStyle: 'italic' }}>{record.ticket_description.length === 0 ? `"There is no ticket description"` : record.ticket_description}</p> : <p style={{ margin: 0 }}>"There is no ticket description"</p>
                      }
                    </div>
                  )}
                // onRowClick={ticket => selectHistoryTicket("selectedTicketHistoryID", ticket.ticket_id)}
                />
              </AutoScrollWindow>
            </Spin>
          )
        }}
      </Mutation>
    )
  }
}
