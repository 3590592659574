import React, { Component } from "react";

import { Mutation, Query } from "react-apollo";
import { FORGOT_PASSWORD } from "../../services/graphql/Mutations/"

import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Modal,
  Typography,
  Spin,
  message
} from "antd";
const { Text } = Typography;

class forgotPasswordForm extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Mutation
        mutation={FORGOT_PASSWORD}
        variables={{ email: this.props.regEmailProp }}
        onCompleted={result => {
          message.success(result.forgotPassword)
          this.props.handleCancel();
        }}
        onError={err => {
          console.log("forgot password error:", err)
          message.error("Sorry, something went wrong")
        }}
      >
        {sendLinkMutation => {
          return (
            <Modal
              title="Please enter your registered e-mail:"
              okText="Submit"
              visible={this.props.modalVisible}
              onCancel={() => {
                this.props.handleCancel();
              }}
              onCancel={() => {
                this.props.handleCancel();
              }}
              onOk={() => {
                if (this.props.regEmailProp.length === 0) {
                  alert("Enter a valid e-mail ID");
                } else {
                  sendLinkMutation();
                }
              }}
            >
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
              >
                <Form.Item label="E-mail:" style={{ marginBottom: 0 }}>
                  {getFieldDecorator("forgotemail")(
                    <Input
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      name="forgotemail"
                      placeholder="Enter your email ID"
                      onChange={event => {
                        this.props.changeState(event.target.value);
                      }}
                    />
                  )}
                </Form.Item>
              </Form>
              <div style={{ textAlign: "center" }}>
                <Text
                  type="primary"
                  style={{ textAlign: "center", fontSize: "10px" }}
                >
                  A password reset link will be sent to your registered E-mail
                  ID.
                </Text>
              </div>
            </Modal>
          );
        }}
      </Mutation>
    );
  }
}
export const WrappedForgotPasswordForm = Form.create({
  name: "forgotpassword_form"
})(forgotPasswordForm);
