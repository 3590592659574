import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { EditTickets } from './editTickets';
import WrappedAddRouteForm from './addRoutes';

import { Query } from 'react-apollo';
import { TICKET_CATEGORY_QUERY, TICKET_ROUTES_QUERY } from '../../../../services/graphql/Queries/'

import { Loading, Error } from "../../../util";

export default class Routes extends Component {
    render() {
        return (
            <Query
                query={TICKET_ROUTES_QUERY}
                variables={{ route_disabled: 0 }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <Loading title={'Fetching Ticket Routes...'} />
                    if (error) return <Error />
                    return (
                        <Query query={TICKET_CATEGORY_QUERY} variables={{ is_active: 1 }}>
                            {(categoryOptions) => {
                                if (categoryOptions.loading) return <Loading title={'Fetching Ticket Categories...'} />
                                if (categoryOptions.error) return <Error />
                                return (
                                    <div>
                                        <Row >
                                            <Col span={16} >
                                                <EditTickets tickets={data.ticketRoutes} />
                                            </Col>
                                            <Col span={8}>
                                                <WrappedAddRouteForm categories={categoryOptions.data.ticketCategories} />
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }}
                        </Query>
                    )
                }}
            </Query>
        )
    }
}
