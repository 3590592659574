import { Table, Popconfirm, Form, Button, Select, Icon, Tooltip, Spin } from 'antd';
import React from "react";

import { Mutation } from 'react-apollo';
import { Query } from 'react-apollo';
import { USERS_QUERY, TICKET_ROUTES_QUERY } from '../../../../services/graphql/Queries/'
import { UPDATE_TICKET_ROUTE_MUTATION } from '../../../../services/graphql/Mutations/'

import { ResizeableTitle } from '../../../util/ResizableTable'
let user_id = null
const EditableContext = React.createContext();
const ButtonGroup = Button.Group
class EditableCell extends React.Component {
  getInput = () => {
    return <Select />
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              initialValue: record[dataIndex],
            })(
              <Query query={USERS_QUERY}>
                {({ loading, error, data }) => {
                  if (loading) return <div>Fetching</div>
                  if (error) return <div>Error</div>
                  return (
                    <Select
                      style={{ width: "100px" }}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      onChange={(value, key) => {
                        user_id = key.key
                      }}

                    >
                      {data.users.map((user) => {
                        return user.permissions.map((permission) => {
                          if ((permission.permission_id.indexOf(0) > -1) ||
                            (permission.permission_id.indexOf(1) > -1)) {
                            return (
                              <Select.Option key={user.user_id} value={user.firstname}>
                                {user.firstname}
                              </Select.Option>)
                          }
                          return null
                        })
                      })
                      }
                    </Select>
                  )
                }}
              </Query>
            )

            }
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingKey: '',
      newUser: null,
      columns: [
        {
          width: 100,
          title: 'Category',
          dataIndex: 'category',
          editable: false,
        },
        {
          width: 100,
          title: 'Sub-Category',
          dataIndex: 'subCategory',
          editable: false,
        },
        {
          width: 100,
          title: 'Location',
          dataIndex: 'location',
          editable: false,
        },
        {
          width: 100,
          title: 'User',
          dataIndex: 'user',
          editable: true,
        },
        {
          width: 100,
          dataIndex: 'operation',
          render: (text, record) => {
            const { editingKey } = this.state;
            const editable = this.isEditing(record);
            return (
              <Mutation
                mutation={UPDATE_TICKET_ROUTE_MUTATION}
                refetchQueries={() => [{ query: TICKET_ROUTES_QUERY, variables: { route_disabled: 0 } }]}
                update={this.handleUpdate}
                onCompleted={() => {
                  user_id = null
                }}
              >
                {(postMutation, { loading, error, data, }) => editable ? (
                  <span>
                    <EditableContext.Consumer>
                      {form => (
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                          <ButtonGroup>
                            <Tooltip placement="topLeft" title="Save changes">
                              <Button
                                type="primary"
                                onClick={() => postMutation({ variables: { ticket_route_id: record.key, ticket_route_user: user_id } })}
                                loading={loading}
                                icon="save"
                              />
                            </Tooltip>
                            <Tooltip placement="topRight" title="Cancel">
                              <Button icon="close-circle" onClick={() => this.cancel(record.key)} />
                            </Tooltip>
                          </ButtonGroup>
                        </div>

                      )}
                    </EditableContext.Consumer>
                  </span>
                ) : (
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                      <Tooltip placement="topLeft" title="Change User">
                        <ButtonGroup>
                          <Button type="primary" disabled={editingKey !== ''}
                            onClick={() => {
                              this.edit(record.key)
                            }}
                            icon="edit"
                          />
                          <Popconfirm title="Are you sure you want to delete this ticket route?" onConfirm={() => postMutation({ variables: { ticket_route_id: record.key, route_disabled: 1 } })} okText="Yes" cancelText="No">
                            <Button type="danger" disabled={editingKey !== ''}
                              icon="delete"
                              loading={loading}
                            />
                          </Popconfirm>
                        </ButtonGroup>
                      </Tooltip>
                    </div>
                  )}
              </Mutation>
            )
          },
        },
      ]
    };
  }


  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    user_id = null
    this.setState({ editingKey: '' });
  };

  handleUpdate = () => {
    this.setState({ ...this.state, editingKey: '' })
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  handleResize = index => (e, { size }) => {
    console.log("handle resize")
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      return { columns: nextColumns }
    })
  }

  components = {
    header: {
      cell: ResizeableTitle,
    },
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
      header: {
        cell: ResizeableTitle,
      },
    };

    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    }).map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));

    const { tickets } = this.props
    console.log('tickets: ', tickets);
    return (
      <div id="usersContainer" style={{ height: '100%', marginLeft: 20 }}>
        <EditableContext.Provider value={this.props.form}>
          <Table
            components={components}
            bordered
            dataSource={tickets.map(data => {
              return {
                key: data.ticket_route_id,
                category: (data.ticket_route_category) ? data.ticket_route_category.ticket_category_name : "All Categories",
                subCategory: (data.ticket_route_sub_category) ? data.ticket_route_sub_category.ticket_sub_category_name : "All Sub-Categories",
                location: data.ticket_route_location.location_name,
                user: data.ticket_route_user.firstname + ' ' + data.ticket_route_user.lastname,
                user_id: data.ticket_route_user.user_id
              }
            })}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              onChange: this.cancel,
            }}
          />

        </EditableContext.Provider>

      </div>
    );
  }
}

export const EditTickets = Form.create({ name: 'settings_table' })(EditableTable);

