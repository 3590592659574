import gql from "graphql-tag";

export const UPDATE_USER_MUTATION = gql`
    mutation updateUser(
        $user_id:Int!,
        $location:Int,
        $price_type:Int,
        $verification:Int,
        $firstname: String,
        $lastname: String,
        $designation: String,
        $company: String,
        $address: String,
        $phone: String,
        $email: String,
        $addUserPermissions: [Int],
        $deleteUserPermssions: [Int]
        ){
        updateUser(
            user_id:$user_id,
            user:{
                location:$location,
                price_type:$price_type,
                verification:$verification,
                firstname:$firstname,
                lastname:$lastname,
                designation:$designation,
                company:$company,
                address:$address,
                phone:$phone,
                email:$email
            }
            addUserPermissions:$addUserPermissions
            deleteUserPermissions:$deleteUserPermssions
        ){
            user_id
            firstname
            lastname
            designation
            date_created
            company
            address
            phone
            email
            verification
            location{
                location_id,
                location_name
            }
            price_type{
                price_name,
                price_type_id
            }
            permissions{
                permission_id
                permission_name
                permission_friendly_name
            }
        }
    }
`;

export const ADD_USERS_MUTATION = gql`
        mutation createUser(
            $firstname:String!,
            $lastname:String,
            $designation:String!,
            $company:String!,
            $phone:String!,
            $address:String!,
            $email:String!,
            $password:String!,
            $verification:Int!,
            $location:Int,
            $price_type:Int,
            $permissions:[Int]
        ){
            createUser(
                user:{
                    firstname:$firstname,
                    lastname:$lastname,
                    designation:$designation,
                    company:$company,
                    phone:$phone,
                    address:$address,
                    password:$password,
                    verification:$verification,
                    email:$email,
                    location:$location,
                    price_type:$price_type
                },
                permissions:$permissions
            )
            {
                user_id
                firstname
                lastname
                designation
                company
                address
                phone
                email
                price_type{
                    price_type_id
                    price_name
                }
                location{
                    location_id
                    location_name
                }
                permissions{
                    permission_id
                    permission_name
                    permission_friendly_name
                }   
                verification
            }
        }
`;