import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Icon,
  Input,
  Table,
  Tooltip,
  Spin,
  Divider
} from "antd";
import AutoScrollWindow from "../../util/AutoScrollWindow";
import { UPDATE_TICKET } from '../../../services/graphql/Mutations/'
import { TICKET_QUERY } from "../../../services/graphql/Queries/"
import { Mutation } from "react-apollo";

import moment from "moment";

import { ResizeableTitle } from '../../util/ResizableTable'

// import { } from '../../../services/'

export default class TicketList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowClicked: 0,
      hoveredRow: 0,
      ishovered: false,
      visible: false,
      pageSize: 5,
      reloadSpinner: false,
      categories: [],
      selectedRowKeys: [],
      selectedTickets: [],
      searchParameter: "",
      columns: [
        {
          width: 120,
          title: "Ticket ID",
          dataIndex: "ticket_id",
          sorter: (a, b) => moment(a.ticket_created_at).isAfter(b.ticket_created_at) ? 1 : -1,
          defaultSortOrder: 'descend',
        },
        {
          width: 230,
          title: "Customer Name",
          dataIndex: "user",
          filters: this.props.users.map(data => {
            return {
              text: `${data.name} #${data.id}`,
              value: data.id
            };
          }),
          onFilter: (value, record) => record.user_id === value
        },
        {
          width: 175,
          title: "Category",
          dataIndex: "ticket_category",
          filters: this.props.categories.map(data => {
            return {
              text: data,
              value: data
            };
          }),
          onFilter: (value, record) => record.ticket_category === value,
        },
        {
          width: 175,
          title: "Sub-Category",
          dataIndex: "ticket_subcategory",
          filters: this.props.subCategories.map(data => {
            return {
              text: data,
              value: data
            };
          }),
          onFilter: (value, record) => record.ticket_subcategory === value
        },
        {
          width: 180,
          title: "Date Created",
          dataIndex: "ticket_created_at",
          sorter: (a, b) => moment(a.ticket_created_at).isBefore(b.ticket_created_at) ? 1 : -1,
          defaultSortOrder: 'descend',
        },
        {
          width: 125,
          key: "action",
          render: (text, record) => {
            return (
              <Mutation
                mutation={UPDATE_TICKET}
                variables={{
                  ticket_id: record.ticket_id,
                  ticket_is_replied: 1
                }}
                refetchQueries={() => {
                  return [{ query: TICKET_QUERY }];
                }}
                onCompleted={data => {
                  record.stopSpinner();
                }}
                onError={err => {
                  console.log("mutation err: ", err);
                  record.stopSpinner();
                }}
                update={(store, { data }) => {
                  const oldTicketList = store.readQuery({
                    query: TICKET_QUERY
                  }).tickets
                  const newTicketList = oldTicketList.map((value) => {
                    if (value.ticket_id === record.ticket_id)
                      return {
                        ...value,
                        ticket_is_replied: 1
                      }
                    return value
                  })
                  store.writeQuery({ query: TICKET_QUERY, data: { tickets: newTicketList } })
                }}
              >
                {(postMutation, { loading, error, data }) => {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Tooltip
                        placement="topLeft"
                        title={
                          record.ticket_is_replied === 1
                            ? "Ticket has been replied too"
                            : "Mark as replied"
                        }
                      >
                        <Button
                          type="primary"
                          disabled={record.ticket_is_replied === 1}
                          onClick={(event) => {
                            event.stopPropagation();
                            record.startSpinner();
                            postMutation();
                          }}
                          icon="inbox"
                        />
                      </Tooltip>
                    </div>
                  );
                }}
              </Mutation>
            )
          }
        }
      ]
    };
  }

  handleResize = index => (e, { size }) => {
    console.log("handle resize")
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      }
      return { columns: nextColumns }
    })
  }

  components = {
    header: {
      cell: ResizeableTitle,
    },
  }

  componentDidMount() {
    setTimeout(() => this.updatePageSize(), 100);
    window.addEventListener("resize", this.updatePageSize);
  }

  updatePageSize = () => {
    const containerHeight = window.innerHeight - 160;
    let pageSize = Math.floor(containerHeight / 60);
    pageSize = pageSize <= 0 ? 1 : pageSize;
    this.setState({ pageSize });
  };

  handleRowClick = key => {
    console.log(key, "hello");
    this.setState({ ...this.state, rowClicked: key });
  };
  handleMouseAction = (hoveredAction, rowKey) => {
    this.setState({
      ...this.state,
      ishovered: hoveredAction,
      hoveredRow: rowKey
    });
  };
  stopSpinner = () => {
    this.setState({
      ...this.state,
      reloadSpinner: false
    });
  };
  startSpinner = () => {
    this.setState({
      ...this.state,
      reloadSpinner: true
    });
  };

  onSelectChange = (selectedRowKeys, record) => {
    console.log(' record: ', record);
    this.setState({
      ...this.state,
      selectedRowKeys,
      selectedTickets: record
    });
  };

  markAsReplied = postMutation => {
    const { selectedTickets } = this.state;
    selectedTickets.map(data => {
      if (data.ticket_is_replied === 0)
        postMutation({ ticket_id: data.ticket_id, ticket_is_replied: 1 });
    });
  };

  resetCheckBox = () => {
    this.setState({
      ...this.state,
      selectedRowKeys: [],
      selectedTickets: []
    });
  };

  render() {
    const {
      pageSize,
      reloadSpinner,
      selectedRowKeys,
      selectedTickets,
      searchParameter
    } = this.state;
    const { dataSource, selectOpenTicket, selectedTicketID } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index),
        onResizeStart: this.onResizeStart,
        onResizeStop: this.onResizeStop
      }),
    }))
    const employeeEmail = JSON.parse(localStorage["USER_DATA"]).email
    return (
      <div>
        <Mutation
          mutation={UPDATE_TICKET}
          refetchQueries={() => {
            return [{ query: TICKET_QUERY }];
          }}
          onCompleted={data => {
            this.stopSpinner();
            this.resetCheckBox();
          }}
          onError={err => {
            console.log("mutation err: ", err);
            this.stopSpinner();
          }}
        >
          {(postMutation, { loading, error, data }) => {
            return (
              <Row
                type="flex"
                style={{
                  padding: "20px",
                  borderRight: "solid 1px #d9d9d9"
                }}
                justify="space-between"
              >
                {/* <Col span={2} style={{ padding: '21px 0 0 8px' }}><Checkbox /></Col> */}
                <Col span={10} style={{ display: "flex", alignSelf: 'center' }}>
                  <Input
                    key="1"
                    prefix={
                      <Icon
                        type="search"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    placeholder="Search by Ticket ID or Customer Name"
                    allowClear
                    onChange={event => {
                      this.setState({
                        ...this.state,
                        searchParameter: event.target.value
                      });
                    }}
                  />
                </Col>
                <Tooltip title={selectedTickets.filter(data => data.ticket_is_replied === 0).length === 0 ? "No tickets selected" : "Mark as replied"}>
                  <Button
                    onClick={() => {
                      this.startSpinner();
                      this.markAsReplied(variables =>
                        postMutation({ variables })
                      );
                    }}
                    disabled={
                      selectedTickets.filter(
                        data => data.ticket_is_replied === 0
                      ).length === 0
                    }
                    style={{ marginRight: '10px' }}
                    type="primary"
                    icon="inbox"
                  />
                </Tooltip>
              </Row>
            );
          }}
        </Mutation>
        <AutoScrollWindow offset={170}>
          <Spin spinning={reloadSpinner}>
            <Table
              bordered
              rowSelection={rowSelection}
              rowClassName={record => "ticketRow " + (selectedTicketID === record.ticket_id ? 'ddd' : '')}
              rowKey={record => record.ticket_id}
              // onRow={(record) => ({
              //   onClick: () => selectOpenTicket("selectedTicketID", record.ticket_id)
              // })}
              dataSource={dataSource
                .filter(({ ticket_id, ticket_created_by }) => {
                  const { firstname, lastname } = ticket_created_by;
                  return (
                    ticket_id
                      .toUpperCase()
                      .indexOf(searchParameter.toUpperCase()) > -1 ||
                    `${firstname} ${lastname}`
                      .toUpperCase()
                      .indexOf(searchParameter.toUpperCase()) > -1
                  );
                })
                .map(
                  ({
                    id,
                    ticket_id,
                    ticket_created_by,
                    ticket_subcategory,
                    ticket_category,
                    ticket_created_at,
                    ticket_is_replied,
                    ticket_is_closed,
                    ticket_description,
                  }) => {
                    const { firstname, lastname, user_id, email } = ticket_created_by;
                    return {
                      ticket_id,
                      user_id,
                      user: `${firstname} ${lastname}`,
                      ticket_category: ticket_category.ticket_category_name,
                      ticket_subcategory: ticket_subcategory ? ticket_subcategory.ticket_sub_category_name : '-',
                      ticket_created_at: moment(
                        parseInt(ticket_created_at)
                      ).format("LLL"),
                      ticket_is_replied,
                      ticket_is_closed,
                      ticket_description,
                      email,
                      // action: () => selectOpenTicket("selectedTicketID", ticket_id),
                      stopSpinner: () => this.stopSpinner(),
                      startSpinner: () => this.startSpinner()
                    };
                  }
                )}
              columns={columns}
              components={this.components}
              pagination={{ pageSize }}
              size="small"
              expandRowByClick
              expandedRowRender={record => (
                <div>
                  <Divider orientation="left" style={{ fontSize: "small" }}>Ticket Description <Icon type="file-text" theme="filled" /></Divider>
                  {
                    record.ticket_description ? <p style={{ margin: 0, fontStyle: 'italic' }}>{record.ticket_description ? (record.ticket_description.length === 0 ? `"There is no ticket description"` : record.ticket_description) : `"There is no ticket description"`}</p> : <p style={{ margin: 0 }}>"There is no ticket description"</p>
                  }
                  <Divider
                    orientation="right"
                    style={{ margin: "10px 0" }}
                  >
                    <Tooltip title="Reply">
                      <a href={`mailto:${record.email}?cc=${employeeEmail}&subject=Ticket #${record.ticket_id} ${record.ticket_description ? record.ticket_description.slice(0, 15) : ""}`}>
                        <Icon type="mail" theme="twoTone" />
                      </a>
                    </Tooltip>
                  </Divider>

                </div>
              )}
            />
          </Spin>
        </AutoScrollWindow>
      </div>
    );
  }
}
