import React, { Component } from 'react'
import './dashboard.css'
import { Button, Select, List, Row, Col, Typography, Switch, Form, Modal, Tabs, Table, Dropdown, Icon, Menu, Input, Skeleton, Result } from 'antd';
import AutoScrollWindow from '../../util/AutoScrollWindow';
import { Query } from 'react-apollo';
import { Mutation } from 'react-apollo';
import {
  LOCATIONS_QUERY,
  PRICE_QUERY,
  PERMISSIONS_QUERY,
  REJECT_USER_MUTATION
} from './dashboardGraphql';

import { UPDATE_USER_MUTATION } from '../../../services/graphql/mutation'
import { USERS_QUERY } from '../../../services/graphql/Queries/'

import moment from 'moment'
import _ from 'lodash'

import Error from "../../util/Error";
import Loading from "../../util/FetchLoading"

import UserTable from './userTable'

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showPrice: false,
      pending_users: null,
      permissions_list: null,
      price_list: null,
      location_list: null,
      assign_user_permission: null,
      delete_user_permission: null,
      location: 0,
      price_type: null,
      user_id: null,
      pendingUserModal: false,
      existingUserModal: false,
      dataSource: null,
      searchParameter: "",
      requestSearchParameter: "",
      selected: {
        selectedUserId: "",
        selectedName: "",
        selectedLocation: null,
        selectedDesignation: "",
        selectedAddress: "",
        selectedPhone: "",
        selectedPrice: "",
      },
      newLocation: "",
      pageSize: 5,
    }
    this.addPermission = []
    this.deletePermission = []
    this.columns = [
      {
        width: 100,
        title: 'Name',
        dataIndex: 'name',
        editable: false,
      },
      {
        width: 100,
        title: 'Created on',
        dataIndex: 'date_created',
        editable: false,
      },
      {
        width: 100,
        title: 'Last ticket',
        dataIndex: 'lastTicket',
      },
      {
        width: 100,
        title: 'Company',
        dataIndex: 'company',
        editable: true
      },
      {
        width: 100,
        title: 'Designation',
        dataIndex: 'designation',
        editable: true
      },
      {
        width: 100,
        title: 'Phone',
        dataIndex: 'phone',
        editable: true
      },
      {
        width: 100,
        title: 'Email',
        dataIndex: 'email',
        editable: true
      },
      {
        width: 100,
        title: 'Address',
        dataIndex: 'address',
        editable: true
      },
    ]
  }

  updatePageSize = () => {
    const containerHeight = window.innerHeight - 160;
    let pageSize = Math.floor(containerHeight / 60);
    pageSize = pageSize <= 0 ? 1 : pageSize;
    this.setState({ pageSize })
  }

  handleApproveClick = (isSuccess, user_id) => {
    this.setState({ ...this.state, showModal: isSuccess, user_id: user_id })
  }

  acceptUser = (postMutation) => {
    postMutation()
    this.setState({
      ...this.state,
      pendingUserModal: false,
      location: 0,
      price_type: 0,
      user_id: 0,
      showModal: false,
      assign_user_permission: [],
      delete_user_permission: [],
    })
  }

  handleCancel = () => {
    this.setState({
      ...this.state,
      pendingUserModal: false,
      existingUserModal: false,
      selected: {
        selectedLocation: null,
        selectedUserId: "",
        selectedName: "",
        selectedAddress: "",
        selectedDesignation: "",
        selectedPhone: "",
        selectedPrice: null,
      },
      newLocation: ""
    })
  }

  handleExistingRowClick = ({ user_id, location_id, designation, address, phone, name, price_type_id }) => {
    this.setState({
      ...this.state,
      existingUserModal: true,
      selected: {
        selectedUserId: user_id,
        selectedLocation: location_id,
        selectedDesignation: designation,
        selectedAddress: address,
        selectedPhone: phone,
        selectedName: name,
        selectedPrice: price_type_id,
      }
    })
    console.log('{location} from handleRowClick: ');
  }

  handlePendingRowClick = ({ user_id, location, designation, address, phone, name, price }) => {
    this.setState({
      ...this.state,
      pendingUserModal: true,
      selected: {
        selectedUserId: user_id,
        selectedLocation: location,
        selectedDesignation: designation,
        selectedAddress: address,
        selectedPhone: phone,
        selectedName: name,
        selectedPrice: price,
      }
    })
    console.log('{location} from handleRowClick: ', this.state.selectedLocation);
  }

  assignPermissions = (checked, key) => {
    let uniquePermissionAssign = [];
    let uniquePermissionDelete = [];
    let index = -1
    if (checked) {
      this.addPermission.push(parseInt(key))
      index = this.deletePermission.indexOf(parseInt(key));
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        this.deletePermission.splice(index, 1);
        uniquePermissionDelete = Array.from(new Set(this.deletePermission));
      }
      uniquePermissionAssign = Array.from(new Set(this.addPermission));

    }
    else {
      this.deletePermission.push(parseInt(key))
      index = this.addPermission.indexOf(parseInt(key));
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        this.addPermission.splice(index, 1);
        uniquePermissionAssign = Array.from(new Set(this.addPermission));
      }
      uniquePermissionDelete = Array.from(new Set(this.deletePermission));
    }
    this.setState({
      ...this.state, assign_user_permission: uniquePermissionAssign,
      delete_user_permission: uniquePermissionDelete
    })
    console.log(this.state.assign_user_permission,
      this.state.delete_user_permission)
  }

  isPresent = (array, { prop, value }) => array.filter(element => element[prop] === value).length > 0

  render() {
    const { showModal, user_id, location, price_type, assign_user_permission, delete_user_permission, newLocation, selected, existingUserModal, pendingUserModal, dataSource } = this.state
    const { selectedUserId, selectedLocation, selectedName, selectedDesignation, selectedAddress, selectedPhone, selectedPrice } = selected
    const { locationID } = JSON.parse(localStorage.getItem('USER_DATA'))
    return (
      <div>
        <Tabs
          type="card"
          tabPosition={this.state.tabPosition}
          tabBarStyle={{ paddingLeft: '10px', marginBottom: '0px' }}
          defaultActiveKey="1"
        >
          <TabPane tab="Requests" key="1">
            <Query
              query={USERS_QUERY}
              variables={{ verification: 0 }}
              pollInterval={30000}
            >
              {({ loading, error, data }) => {

                if (loading) return (
                  <Loading />
                )
                if (error) {
                  console.log('error: ', error);
                  return (
                    <Error />
                  )
                }
                return (
                  <>
                    <Row
                      type="flex"
                      style={{
                        padding: "15px",
                        borderRight: "solid 1px #d9d9d9"
                      }}
                      justify="space-between"
                    >
                      {/* <Col span={2} style={{ padding: '21px 0 0 8px' }}><Checkbox /></Col> */}
                      <Col span={10} style={{ display: "flex", alignSelf: 'center' }}>
                        <Input
                          key="1"
                          prefix={
                            <Icon
                              type="search"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Search by Name, Email or Phone no."
                          allowClear
                          onChange={event => {
                            this.setState({
                              ...this.state,
                              requestSearchParameter: event.target.value
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <UserTable
                      columns={this.columns.map(col => {
                        if (col.dataIndex === "name")
                          return {
                            ...col,
                            filters: _.uniq(
                              data.users.map(
                                user => `${user.firstname} ${user.lastname}`
                              )
                            ).map(user => ({
                              text: user,
                              value: user
                            })),
                            onFilter: (value, record) => record.name.indexOf(value) === 0,
                          }
                        if (col.dataIndex === "company")
                          return {
                            ...col,
                            filters: _.uniq(
                              data.users.map(
                                ({ company }) => company
                              )
                            ).map(company => ({
                              text: company,
                              value: company
                            })),
                            onFilter: (value, record) => record.company.indexOf(value) === 0,
                          }
                        if (col.dataIndex === "email")
                          return {
                            ...col,
                            filters: _.uniq(
                              data.users.map(
                                ({ email }) => email
                              )
                            ).map(email => ({
                              text: email,
                              value: email
                            })),
                            onFilter: (value, record) => record.email.indexOf(value) === 0,
                          }
                        return col
                      }).filter(({ dataIndex }) => dataIndex !== 'lastTicket')}
                      dataSource={data.users
                        .filter(({ user_id, firstname, lastname, permissions, email, phone }) => {
                          return (
                            `${firstname} ${lastname}`
                              .toUpperCase()
                              .indexOf(this.state.requestSearchParameter.toUpperCase()) > -1 ||
                            email.toUpperCase()
                              .indexOf(this.state.requestSearchParameter.toUpperCase()) > -1 ||
                            phone.toUpperCase()
                              .indexOf(this.state.requestSearchParameter.toUpperCase()) > -1
                          )
                        })
                        .filter(({ verification }) => verification === 0).map(({ user_id, firstname, lastname, verification, phone, email, address, company, designation, ticket_created_at, date_created, }) => {
                          // console.log('date_created: ', date_created);
                          return {
                            key: user_id,
                            user_id,
                            name: firstname + " " + lastname,
                            phone,
                            email,
                            address,
                            company,
                            designation,
                            date_created: moment(parseInt(date_created)).format("LLL"),
                            lastTicket: ticket_created_at ? ticket_created_at : '-',
                            verification
                          }
                        })}
                      name={"pendingUser"}
                    />
                  </>
                )
              }}
            </Query>
          </TabPane>
          <TabPane tab="Existing" key="2">
            <Query
              query={USERS_QUERY}
              variables={{ verification: 4 }}
              pollInterval={30000}
            >
              {({ loading, error, data }) => {
                if (loading) return (
                  <Loading />
                )
                if (error) {
                  return (
                    <Error />
                  )
                }
                return (
                  <>
                    <Row
                      type="flex"
                      style={{
                        padding: "15px",
                        borderRight: "solid 1px #d9d9d9"
                      }}
                      justify="space-between"
                    >
                      {/* <Col span={2} style={{ padding: '21px 0 0 8px' }}><Checkbox /></Col> */}
                      <Col span={10} style={{ display: "flex", alignSelf: 'center' }}>
                        <Input
                          key="1"
                          prefix={
                            <Icon
                              type="search"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Search by Name, Email or Phone no."
                          allowClear
                          onChange={event => {
                            this.setState({
                              ...this.state,
                              searchParameter: event.target.value
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <UserTable
                      name={"existingUser"}
                      columns={this.columns.map(col => {
                        if (col.dataIndex === "name")
                          return {
                            ...col,
                            filters: _.uniq(
                              data.users.map(
                                user => `${user.firstname} ${user.lastname}`
                              )
                            ).map(user => ({
                              text: user,
                              value: user
                            })),
                            onFilter: (value, record) => record.name.indexOf(value) === 0,
                          }
                        if (col.dataIndex === "company")
                          return {
                            ...col,
                            filters: _.uniq(
                              data.users.map(
                                ({ company }) => company
                              )
                            ).map(company => ({
                              text: company,
                              value: company
                            })),
                            onFilter: (value, record) => record.company.indexOf(value) === 0,
                          }
                        if (col.dataIndex === "email")
                          return {
                            ...col,
                            filters: _.uniq(
                              data.users.map(
                                ({ email }) => email
                              )
                            ).map(email => ({
                              text: email,
                              value: email
                            })),
                            onFilter: (value, record) => record.email.indexOf(value) === 0,
                          }
                        return col
                      }).filter((data) => {
                        if (data.dataIndex !== "action")
                          return data
                      })}
                      locations={_.uniq(
                        data.users.map(
                          ({ location }) => {
                            if (location)
                              return location.location_name
                            return null
                          }
                        ).filter((location) => location)
                      )}
                      dataSource={data.users.filter(({ location, permissions }) => ((this.isPresent(permissions, { prop: 'permission_id', value: "2" }))))
                        .filter(({ firstname, lastname, email, phone }) => {
                          return (
                            `${firstname} ${lastname}`
                              .toUpperCase()
                              .indexOf(this.state.searchParameter.toUpperCase()) > -1 ||
                            email.toUpperCase()
                              .indexOf(this.state.searchParameter.toUpperCase()) > -1 ||
                            phone.toUpperCase()
                              .indexOf(this.state.searchParameter.toUpperCase()) > -1
                          );
                        })
                        .map(({ user_id, firstname, lastname, company, phone, email, location, designation, price_type, address, ticket_created_at, date_created, verification }) => {
                          return {
                            key: user_id,
                            user_id,
                            name: firstname + " " + lastname,
                            company,
                            date_created: moment(parseInt(date_created)).format("LLL"),
                            phone,
                            email,
                            location: location.location_name,
                            designation,
                            price_type: price_type.price_name,
                            price_type_id: price_type.price_type_id,
                            address,
                            lastTicket: ticket_created_at ? moment(parseInt(ticket_created_at)).format("LLL") : '-',
                            location_id: location.location_id,
                            verification
                          }
                        })}
                    />
                  </>
                )
              }}
            </Query>
          </TabPane>
        </Tabs>
      </div>
    )
  }
}
