import React, { Component } from 'react'
import {
  Table,
  Button,
  Modal,
  Switch,
  Form,
  Input,
  Select,
  Steps,
  Row,
  Col,
  Typography,
  Spin,
  Icon
} from 'antd';
import { Query, Mutation } from 'react-apollo';
import {
  ADD_USERS_MUTATION,
  CHANGE_PASSWORD_MUTATION,
} from '../../../services/graphql/Mutations/';

import UserTable from '../Dashboard/userTable'
import { UserDetails } from './userPersonalDetailsForm'

import { Loading, Error } from '../../util'
import { USERS_QUERY, LOCATIONS_QUERY, PRICE_QUERY, PERMISSIONS_QUERY } from '../../../services/graphql/Queries/'

import _ from 'lodash'

const { Text } = Typography;
const { Step } = Steps;
const { TextArea } = Input;

let personalDetailRef = null
export default class Users extends Component {
  componentDidMount() {
    setTimeout(() => this.updatePageSize(), 100)
    window.addEventListener("resize", this.updatePageSize);

  }
  constructor(props) {
    super(props)
    this.state = {
      modalVisible: false,
      showChangePassword: false,
      selectedUserDetails: null,
      pageSize: 5,
      showCreateUser: false,
      currentStep: 0,
      users_list: [],
      firstname: "",
      lastname: "",
      designation: "",
      company: "",
      address: "",
      phone: "",
      email: "",
      password: "",
      location: 0,
      price_type: 0,
      user_id: 0,
      location_list: [],
      price_list: [],
      permissions_list: [],
      assign_user_permission: [],
      delete_user_permission: [],
      old_password: "",
      confirm_password: "",
      new_password: "",
      passwordChangeResultModal: false,
      passwordResult: "",
      searchParameter: "",
      companyFilters: []
    }
    this.addPermission = []
    this.deletePermission = []
    this.testCol = [
      {
        width: 100,
        title: 'Name',
        dataIndex: 'name',
        editable: false,
      },
      {
        width: 100,
        title: 'Last ticket',
        dataIndex: 'lastTicket',
        editable: false,
      },
      {
        width: 100,
        title: 'Company',
        dataIndex: 'company',
        editable: true,
      },
      {
        width: 100,
        title: 'Designation',
        dataIndex: 'designation',
        editable: true,
      },
      {
        width: 100,
        title: 'Phone',
        dataIndex: 'phone',
        editable: true,
      },
      {
        width: 100,
        title: 'Email',
        dataIndex: 'email',
        editable: true,
      },
      {
        width: 100,
        title: 'Address',
        dataIndex: 'address',
        editable: true,
      },
    ]

  }
  next() {
    const currentStep = this.state.currentStep + 1;
    this.setState({ currentStep });
  }

  prev() {
    const currentStep = this.state.currentStep - 1;
    this.setState({ currentStep });
  }

  changeState = (prop, value) => {
    this.setState({
      ...this.state,
      [prop]: value
    })
  }

  updatePageSize = () => {
    const containerHeight = window.innerHeight - 160;
    let pageSize = Math.floor(containerHeight / 60);
    pageSize = pageSize <= 0 ? 1 : pageSize;
    this.setState({ pageSize })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePageSize);
  }
  handleRowClick = (record, key) => {
    this.setState({
      ...this.state,
      modalVisible: true,
      user_id: key,
      selectedUserDetails: record,
      location: record.location_id,
      price_type: record.price_type_id
    })

  }
  assignPermissions = (checked, key) => {
    let uniquePermissionAssign = [];
    let uniquePermissionDelete = [];
    let index = -1
    if (checked) {
      this.addPermission.push(parseInt(key))
      index = this.deletePermission.indexOf(parseInt(key));
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        this.deletePermission.splice(index, 1);
        uniquePermissionDelete = Array.from(new Set(this.deletePermission));
      }
      uniquePermissionAssign = Array.from(new Set(this.addPermission));

    }
    else {
      this.deletePermission.push(parseInt(key))
      index = this.addPermission.indexOf(parseInt(key));
      if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        this.addPermission.splice(index, 1);
        uniquePermissionAssign = Array.from(new Set(this.addPermission));
      }
      uniquePermissionDelete = Array.from(new Set(this.deletePermission));
    }
    this.setState({
      ...this.state, assign_user_permission: uniquePermissionAssign,
      delete_user_permission: uniquePermissionDelete
    })
    console.log(this.state.assign_user_permission,
      this.state.delete_user_permission)
  }
  handleCreateUser = () => {
    this.addPermission = []
    this.deletePermission = []
    this.setState({
      ...this.state, firstname: "", lastname: "", designation: "",
      company: "", phone: "", address: "", password: "", email: "", location: 0,
      price_type: 0, assign_user_permission: [], delete_user_permission: [],
      currentStep: 0,
    })

  }
  handleOK = () => {
    this.addPermission = []
    this.deletePermission = []
    this.setState({
      ...this.state, location: 0, price_type: 0, user_id: 0,
      modalVisible: false, assign_user_permission: [], delete_user_permission: [],
    })

  }
  handleCreateUserModal = () => {
    this.addPermission = []
    this.deletePermission = []
    this.setState({
      ...this.state, user_id: 0, currentStep: 0,
      showCreateUser: false, firstname: "", lastname: "", designation: "",
      company: "", phone: "", address: "", password: "", email: "", location: 0,
      price_type: 0, assign_user_permission: [], delete_user_permission: [],
    })
  }
  handleShowChangePasswordModal = () => {
    this.setState({ ...this.state, showChangePassword: true })
  }
  handlePasswordChange = (postMutation) => {
    let result;
    const { new_password, confirm_password, email, selectedUserDetails } = this.state
    new_password === confirm_password ? result = 1 : result = 0
    if (!result) {
      this.setState({
        ...this.setState, passwordChangeResultModal: true,
        passwordResult: "New and confirm password does not match"
      })
    }
    else if (!(selectedUserDetails.email === email)) {
      this.setState({
        ...this.setState, passwordChangeResultModal: true,
        passwordResult: "Invalid email Id.Please enter the registered email id"
      })
    }
    else {
      postMutation()
      this.setState({
        ...this.setState, passwordChangeResultModal: true,
        passwordResult: "Password changed successfully"
      })
    }

  }
  handleUpdatePassword = () => {
    this.setState({ ...this.state, old_password: "", new_password: "", email: "", showChangePassword: false })
  }
  isPresent = (array, { prop, value }) => array.filter(element => element[prop] === value).length > 0
  render() {
    const {
      modalVisible,
      selectedUserDetails,
      pageSize,
      currentStep,
      showChangePassword,
      firstname,
      lastname,
      designation,
      company,
      address,
      phone,
      email,
      password,
      price_type,
      location,
      user_id,
      old_password,
      new_password,
      passwordChangeResultModal,
      assign_user_permission,
      delete_user_permission,
      passwordResult,
      searchParameter
    } = this.state
    const steps = [
      {
        title: 'Personal Details',
      },
      {
        title: 'Assign Permissions',
        content:
          <Query query={LOCATIONS_QUERY}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return <Error />
              return (
                <Query query={PRICE_QUERY}>
                  {(priceOptions) => {
                    if (priceOptions.loading) return <Loading />
                    if (priceOptions.error) return <Error />
                    return (
                      <Query query={PERMISSIONS_QUERY}>
                        {(permissionOptions) => {
                          if (permissionOptions.loading) return <Loading />
                          if (permissionOptions.error) return <Error />
                          return (
                            <Form
                              wrapperCol={{ span: 24 }}
                              layout="vertical">
                              <Form.Item label="Location ">
                                <Select
                                  onChange={(value, key) => {
                                    this.setState({
                                      ...this.state,
                                      location: key.key
                                    })
                                  }}>
                                  {data.locations.map(location => (
                                    <Select.Option key={location.location_id}
                                      value={location.location_name}>{location.location_name}</Select.Option>
                                  ))}

                                </Select>
                              </Form.Item>


                              <Form.Item label="Price ">
                                <Select onChange={(value, key) => {
                                  this.setState({
                                    ...this.state,
                                    price_type: key.key
                                  })

                                }}>
                                  {priceOptions.data.pricetypes.map(price => (
                                    <Select.Option key={price.price_type_id}
                                      value={price.price_name}>{price.price_name}</Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>

                              <Row type="flex" justify="center" gutter={[8, 8]}>
                                {permissionOptions.data.permissions.map(permission => (
                                  <Col span={24 / permissionOptions.data.permissions.length}>
                                    <Form.Item key={permission.permission_id} label={permission.permission_name}>
                                      <Switch onChange={(checked) => {
                                        this.assignPermissions(checked,
                                          permission.permission_id)
                                      }} />
                                    </Form.Item>
                                  </Col>
                                ))
                                }
                              </Row>
                            </Form>
                          )
                        }}
                      </Query>
                    )
                  }}
                </Query>
              )
            }}
          </Query>
      },

    ];
    return (
      <Query
        query={USERS_QUERY}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <Error />
          const userData = data.users.filter(({ firstname, verification, location }) => {
            // if (location === null)
            //   console.log('location: ', firstname, verification);

            return (verification > 1 && location)
          })
          // console.log('userData: ', userData);
          return (
            <div id="usersContainer" style={{ height: '100%' }}>
              <Row
                type="flex"
                style={{
                  padding: "15px",
                  borderRight: "solid 1px #d9d9d9"
                }}
                justify="space-between"
              >
                {/* <Col span={2} style={{ padding: '21px 0 0 8px' }}><Checkbox /></Col> */}
                <Col span={10} style={{ display: "flex", alignSelf: 'center' }}>
                  <Input
                    key="1"
                    prefix={
                      <Icon
                        type="search"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    placeholder="Search by Employee ID, Name, Email or Phone no."
                    allowClear
                    onChange={event => {
                      this.setState({
                        ...this.state,
                        searchParameter: event.target.value
                      });
                    }}
                  />
                </Col>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      showCreateUser: true
                    })
                  }}
                >
                  Create Employee
              </Button>
              </Row>
              <UserTable
                name={"existingUser"}
                action={(record) => { this.handleRowClick(record, record.key) }}
                locations={_.uniq(
                  userData.map(
                    ({ location }) => location.location_name
                  )
                )}
                columns={this.testCol.map(col => {
                  if (col.dataIndex === "name")
                    return {
                      ...col,
                      filters: _.uniq(
                        userData.map(
                          user => `${user.firstname} ${user.lastname}`
                        )
                      ).map(user => ({
                        text: user,
                        value: user
                      })),
                      onFilter: (value, record) => record.name.indexOf(value) === 0,
                    }
                  if (col.dataIndex === "company")
                    return {
                      ...col,
                      filters: _.uniq(
                        userData.map(
                          ({ company }) => company
                        )
                      ).map(company => ({
                        text: company,
                        value: company
                      })),
                      onFilter: (value, record) => record.company.indexOf(value) === 0,
                    }
                  if (col.dataIndex === "email")
                    return {
                      ...col,
                      filters: _.uniq(
                        userData.map(
                          ({ email }) => email
                        )
                      ).map(email => ({
                        text: email,
                        value: email
                      })),
                      onFilter: (value, record) => record.email.indexOf(value) === 0,
                    }
                  return col
                }).filter(({ dataIndex }) => dataIndex !== 'lastTicket')}
                dataSource={userData
                  .filter(({ user_id, firstname, lastname, permissions, email, phone }) => {
                    return (
                      (user_id
                        .toUpperCase()
                        .indexOf(searchParameter.toUpperCase()) > -1 ||
                        email.toUpperCase()
                          .indexOf(this.state.searchParameter.toUpperCase()) > -1 ||
                        phone.toUpperCase()
                          .indexOf(this.state.searchParameter.toUpperCase()) > -1 ||
                        `${firstname} ${lastname}`
                          .toUpperCase()
                          .indexOf(searchParameter.toUpperCase()) > -1) && (this.isPresent(permissions, { prop: 'permission_id', value: "1" }) ||
                            this.isPresent(permissions, { prop: 'permission_id', value: "0" }))
                    );
                  })
                  .map(({ user_id, firstname, lastname, phone, email, address, company, designation, ticket_created_at, location, price_type, verification }) => {
                    return {
                      key: user_id,
                      user_id,
                      name: firstname + " " + lastname,
                      phone,
                      email,
                      address,
                      company,
                      designation,
                      lastTicket: ticket_created_at ? ticket_created_at : '-',
                      location: location,
                      price_type: price_type ? price_type.price_name : "-",
                      price_type_id: price_type ? price_type.price_type_id : "-",
                      location_id: location ? location.location_id : "-",
                      location: location ? location.location_name : "-",
                      verification
                    }
                  })}
              />


              {(selectedUserDetails) ? (
                <Modal
                  style={{ top: 20 }}
                  title="Customer Details"
                  visible={modalVisible}
                  onCancel={this.handleOK}
                  footer={[
                    <div style={{ display: "flex" }}>
                      <Button key="Cancel" onClick={this.handleOK}>
                        Cancel
                      </Button>
                      {/* <Mutation mutation={UPDATE_USER_MUTATION} variables={{
                        // user_id: parseInt(user_id),
                        // location: parseInt(location),
                        // price_type: parseInt(price_type),
                        // addUserPermissions: assign_user_permission,
                        // deleteUserPermissions: delete_user_permission,
                      }}
                        // onCompleted={USERS_QUERY}
                        // refetchQueries={'USERS_QUERY'}
                        update={this.handleOK}>
                        {(postMutation, { loading, error, data, }) => (
                          <div>
                            <Button type="primary" onClick={postMutation}>
                              Submit
                             </Button>
                            {loading && <p>Loading...</p>}
                            {error && <p>Error! Please try again</p>}
                            {console.log("dataupdate", data)}
                          </div>
                        )}
                      </Mutation> */}
                    </div>
                  ]}
                >
                  <Form labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal">
                    <Form.Item label="Name: ">
                      <Input readOnly value={selectedUserDetails.name} />
                    </Form.Item>
                    <Form.Item label="Designation: ">
                      <Input readOnly value={selectedUserDetails.designation} />
                    </Form.Item>
                    <Form.Item label="Address: ">
                      <Input readOnly value={selectedUserDetails.address} />
                    </Form.Item>
                    <Form.Item label="Phone: ">
                      <Input readOnly value={selectedUserDetails.phone} />
                    </Form.Item>
                    <Query query={LOCATIONS_QUERY}>
                      {({ loading, error, data }) => {
                        if (loading) return <div>Fetching</div>
                        if (error) return <div>Error</div>
                        return (
                          <Form.Item label="Location ">
                            <Select defaultValue={selectedUserDetails.location}
                              onChange={(value, key) => {
                                this.setState({
                                  ...this.state,
                                  location: key.key
                                })
                              }}>
                              {data.locations.map(location => (
                                <Select.Option key={location.location_id}
                                  value={location.location_name}>
                                  {location.location_name}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )
                      }}
                    </Query>

                    <Query query={PRICE_QUERY}>
                      {({ loading, error, data }) => {
                        if (loading) return <div>Fetching</div>
                        if (error) return <div>Error</div>
                        return (
                          <Form.Item label="Price ">
                            <Select defaultValue={selectedUserDetails.price_type}
                              onChange={(value, key) => {
                                this.setState({
                                  ...this.state,
                                  price_type: key.key
                                })

                              }}>
                              {data.pricetypes.map(price => (
                                <Select.Option key={price.price_type_id}
                                  value={price.price_name}>{price.price_name}</Select.Option>
                              ))}

                            </Select>
                          </Form.Item>
                        )
                      }}
                    </Query>
                    <Query query={PERMISSIONS_QUERY}>
                      {({ loading, error, data }) => {
                        if (loading) return <div>Fetching</div>
                        if (error) return <div>Error</div>
                        return (
                          data.permissions.map(permission => (
                            <Form.Item key={permission.permission_id} label={permission.permission_name}>

                              <Switch
                                defaultChecked={selectedUserDetails.permission_id.indexOf(permission.permission_id) !== -1}
                                onChange={(checked) => {
                                  this.assignPermissions(checked,
                                    permission.permission_id)
                                }} />

                            </Form.Item>
                          )))
                      }}
                    </Query>
                    <Form.Item>
                      <Button type="primary" onClick={this.handleShowChangePasswordModal}>
                        Change Password
                    </Button>
                    </Form.Item>
                  </Form>

                </Modal>) : ""}
              <Modal
                style={{ top: 20 }}
                title="Change Password"
                visible={showChangePassword}
                onCancel={() => { this.handleUpdatePassword() }}
                footer={[
                  <div style={{ display: "flex" }}>
                    <Button key="Cancel" onCancel={() => { this.handleUpdatePassword() }}>
                      Cancel
                    </Button>
                    <Mutation mutation={CHANGE_PASSWORD_MUTATION} variables={{
                      email: email,
                      new_password: old_password,
                      old_passord: new_password,
                    }}
                      // onCompleted={USERS_QUERY}
                      // refetchQueries={'USERS_QUERY'}
                      update={() => { this.handleUpdatePassword() }}>
                      {(postMutation, { loading, error, data, }) => (
                        <div>
                          <Button type="primary" onClick={() => { this.handlePasswordChange(postMutation) }}>
                            Submit
                                        </Button>
                          {loading && <p>Loading...</p>}
                          {error && <p>Error :( Please try again</p>}
                          {console.log("error", error)}
                        </div>
                      )}
                    </Mutation>
                  </div>
                ]}
              >
                <Form labelCol={{ span: 6 }}
                  wrapperCol={{ span: 14 }}
                  layout="horizontal">
                  <Form.Item>
                    <Text type="secondary">
                      Hey please all the credentials to generate a new password...
                    </Text>
                  </Form.Item>
                  <Form.Item label="Email: ">
                    <Input onChange={(event) => {
                      this.setState({
                        ...this.state,
                        email: event.target.value
                      })
                    }} />
                  </Form.Item>
                  <Form.Item label="Old Password: ">
                    <Input.Password onChange={(event) => {
                      this.setState({
                        ...this.state,
                        old_password: event.target.value
                      })
                    }} />
                  </Form.Item>
                  <Form.Item label="New Password: ">
                    <Input.Password onChange={(event) => {
                      this.setState({
                        ...this.state,
                        new_password: event.target.value
                      })
                    }} />
                  </Form.Item>
                  <Form.Item label="Confirm Password: ">
                    <Input.Password onChange={(event) => {
                      this.setState({
                        ...this.state,
                        confirm_password: event.target.value
                      })
                    }} />
                  </Form.Item>
                </Form>

              </Modal>
              <UserDetails
                // this.assignPermissions(checked,
                //                         permission.permission_id)
                users={data.users}
                assignPermissions={(checked, permissionID) => this.assignPermissions(checked, permissionID)}
                handleCreateUserModal={() => this.handleCreateUserModal()}
                next={() => this.next()}
                prev={() => this.prev()}
                handleCancel={() => {
                  this.setState({
                    ...this.state,
                    showCreateUser: false
                  })
                }}
                // details={firstname, lastname, email, password, designation, company, phone, address, currentStep, price_type, assign_user_permission, location}
                visible={this.state.showCreateUser}
                steps={[
                  {
                    title: 'Personal Details',
                  },
                  {
                    title: 'Assign Permissions'
                  }
                ]}
                // editDetail={(prop, value) => this.changeState(prop, value)}
                wrappedComponentRef={
                  formRef => {
                    personalDetailRef = formRef;
                  }
                }
                onOk={this.handleCreateUserModal}
                onCancel={this.handleCreateUserModal}
                assignPermissions={(value, prop) => this.assignPermissions(value, prop)}
              />
              {/* <Modal
                style={{ top: 20 }}
                title="Create User"
                visible={!this.state.showCreateUser} //TODO: remove NOT operator
                onOk={this.handleCreateUserModal}
                onCancel={this.handleCreateUserModal}
                footer={currentStep === steps.length - 1 ? [
                  <Button
                    style={{ marginLeft: 8 }}
                    onClick={() => this.prev()}
                  >
                    Previous
                  </Button>,
                  <Button type="primary">
                    Done
                  </Button>

                ] : [
                    <Button
                      type="primary"
                    >
                      Next
                    </Button>
                  ]}
              >
                <div>
                  <Steps current={currentStep} style={{ marginBottom: 20 }}>
                    {steps.map(item => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                  <div className="steps-content">{steps[currentStep].content}</div>
                  <div className="steps-action">
                    {currentStep < steps.length - 1 && (
                      <Button type="primary" onClick={() => this.next()}>
                        Next
                      </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                      <Mutation mutation={ADD_USERS_MUTATION} variables={{
                        firstname: firstname,
                        lastname: lastname,
                        designation: designation,
                        company: company,
                        phone: phone,
                        address: address,
                        password: password,
                        email: email,
                        verification: 4,
                        location: parseInt(location),
                        price_type: parseInt(price_type),
                        permissions: assign_user_permission,
                      }}
                        update={this.handleCreateUser}>
                        {(postMutation, { loading, error, data, }) => (
                          <div>
                            <Button type="primary" onClick={postMutation}>
                              Done
                                    </Button>
                            {loading && <p>Loading...</p>}
                            {error && <p>Error :( Please try again</p>}
                          </div>
                        )}
                      </Mutation>
                    )}
                    {currentStep > 0 && (
                      <Button style={{ marginLeft: 8 }}
                        onClick={() => this.prev()}>
                        Previous
                      </Button>
                    )}
                  </div>
                </div>
              </Modal> */}
              <Modal
                style={{ top: 20 }}
                title="Create User"
                visible={passwordChangeResultModal}
                onOk={() => {
                  this.setState({ ...this.state, passwordChangeResultModal: false })
                }}
                onCancel={() => {
                  this.setState({ ...this.state, passwordChangeResultModal: false })
                }}
              >
                <Text type="secondary">{passwordResult}</Text>
              </Modal>
            </div >
          )
        }
        }
      </Query >
    )
  }
}
