import { Tree, Button, Modal, Form, Icon, Input, Spin, message } from 'antd';
import React, { Component } from 'react'
import AutoScrollWindow from '../../../util/AutoScrollWindow';

import { Query, Mutation } from 'react-apollo';

import { TICKET_CATEGORY_QUERY } from '../../../../services/graphql/Queries/'
import { UPDATE_TICKET_CATEGORY, UPDATE_TICKET_SUBCATEGORY } from '../../../../services/graphql/Mutations/'
import _ from 'lodash'

const { TreeNode } = Tree;

export default class EditCategory extends Component {
  state = {
    expandedKeys: ['title'],
    autoExpandParent: true,
    checkedKeys: [],
    selectedKeys: [],
    visible: false,
    edit: {
      key: null,
      title: null,
      isSubCat: false
    },
  };

  onExpand = expandedKeys => {
    // console.log('onExpand', expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      ...this.state,
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onCheck = checkedKeys => {
    // console.log('onCheck', checkedKeys);
    this.setState({ ...this.state, checkedKeys });
  };

  onSelect = (selectedKeys, info) => {
    // console.log('onSelect', info);
    this.setState({ ...this.state, selectedKeys });
  };

  showModal = () => {
    this.setState({
      ...this.state,
      visible: true,
    });
  };

  handleOk = (editCat, editSubCat) => {
    if (this.state.edit.isSubCat)
      editSubCat({
        variables: {
          ticket_sub_category_id: this.state.edit.key,
          ticket_sub_category_name: this.state.edit.title
        }
      })
    else
      editCat({
        variables: {
          ticket_category_id: this.state.edit.key,
          ticket_category_name: this.state.edit.title
        }
      })
    this.setState({
      ...this.state,
      visible: false,
      checkedKeys: []
    });
  };

  handleCancel = e => {
    // console.log(e);
    this.setState({
      ...this.state,
      visible: false,
    });
  };

  edit(key) {
    // console.log('key: ', key);
    this.setState({
      ...this.state,
      visible: true,
      edit: {
        key: key.id,
        title: key.title,
        isSubCat: key.isSubCat,
        is_active: key.is_active
      }
    });
  }

  renderTreeNodes = (data, isSubCat = false) =>
    data.filter(category => category.is_active === 1).map(item => {
      if (item.ticket_sub_categories) {
        return (
          <TreeNode title={item.ticket_category_name} key={JSON.stringify({ id: item.ticket_category_id, title: item.ticket_category_name, isSubCat, is_active: item.is_active })} dataRef={item}>
            {this.renderTreeNodes(item.ticket_sub_categories, true)}
          </TreeNode>
        );
      }
      return <TreeNode title={item.ticket_category_name} key={JSON.stringify({ id: item.ticket_category_id, title: item.ticket_category_name, isSubCat, is_active: item.is_active })} dataRef={item} />;
    });

  renderDisabledTreeNodes = (data, isSubCat = false) =>
    data.map(item => {
      console.log('item: ', item);
      if (item.ticket_sub_categories) {
        console.log('item.ticket_sub_categories: ', item.ticket_sub_categories);
        return (
          <TreeNode title={item.ticket_category_name} key={JSON.stringify({ id: item.ticket_category_id, title: item.ticket_category_name, isSubCat, is_active: 0, disabled: true })} dataRef={item}>
            {this.renderDisabledTreeNodes(item.ticket_sub_categories.filter(subCat => subCat.is_active === 0), true)}
          </TreeNode>
        );
      }
      return <TreeNode title={item.ticket_category_name} key={JSON.stringify({ id: item.ticket_category_id, title: item.ticket_category_name, isSubCat, is_active: 0, disabled: true })} dataRef={item} />;
    });

  disableCategories = async (disableCategory, disableSubCategory) => {
    const { checkedKeys } = this.state
    await checkedKeys.map(data => {
      const category = JSON.parse(data)
      if (category.isSubCat)
        disableSubCategory({
          variables: {
            ticket_sub_category_id: category.id,
            is_active: 0
          }
        })
      else
        disableCategory({
          variables: {
            ticket_category_id: category.id,
            is_active: 0
          }
        })
    })
    this.setState({
      ...this.state,
      checkedKeys: []
    })
  }

  enableCategories = async (enableCategory, enableSubCategory) => {
    const { checkedKeys } = this.state
    await checkedKeys.map(data => {
      const category = JSON.parse(data)
      if (category.isSubCat)
        enableSubCategory({
          variables: {
            ticket_sub_category_id: category.id,
            is_active: 1
          }
        })
      else
        enableCategory({
          variables: {
            ticket_category_id: category.id,
            is_active: 1
          }
        })
    })
    this.setState({
      ...this.state,
      checkedKeys: []
    })
  }

  render() {
    const { edit, expandedKeys, autoExpandParent, selectedKeys, visible, checkedKeys } = this.state
    const { categories } = this.props
    console.log('categories: ', categories);
    // console.log(': ', checkedKeys, checkedKeys.filter(data => JSON.parse(data).is_active === 0).length);
    // console.log('checkedKeys.length: ', checkedKeys.length);
    return (
      <Mutation
        mutation={UPDATE_TICKET_CATEGORY}
        refetchQueries={() => [{ query: TICKET_CATEGORY_QUERY }]}
        onCompleted={() => {
          message.success("Ticket Category has been successfully edited")
        }}
        onError={(err) => {
          console.log('err: ', err);
          message.error("Sorry, something went wrong")
        }}
        update={(cache, { data }) => {
          const updatedCategory = data.updateTicketCategory
          const oldCategoryList = cache.readQuery({ query: TICKET_CATEGORY_QUERY }).ticketCategories
          const newCategoryList = oldCategoryList.map(value => {
            if (value.ticket_category_id === updatedCategory.ticket_category_id)
              return updatedCategory
            return value
          })
          cache.writeQuery({ query: TICKET_CATEGORY_QUERY, data: { ticketCategories: newCategoryList } })
        }}
      >
        {(postMutation, { loading, error, value }) => {
          return (
            <Mutation
              mutation={UPDATE_TICKET_SUBCATEGORY}
              refetchQueries={() => [{ query: TICKET_CATEGORY_QUERY }]}
              update={(cache, { data }) => {
                const { updateTicketSubCategory } = data
                const oldCategoryList = cache.readQuery({ query: TICKET_CATEGORY_QUERY }).ticketCategories
                const newCategoryList = oldCategoryList.map(category => {
                  if (category.ticket_category_id === updateTicketSubCategory.ticket_category)
                    return {
                      ...category,
                      ticket_sub_categories: category.ticket_sub_categories.map(subCat => {
                        if (subCat.ticket_category_id === updateTicketSubCategory.ticket_category.ticket_category_id)
                          return updateTicketSubCategory
                        return subCat
                      })
                    }
                  return category
                })
                cache.writeQuery({ query: TICKET_CATEGORY_QUERY, data: { ticketCategories: newCategoryList } })
              }}
              onCompleted={() => {
                message.success("Ticket Category has been successfully edited")
                this.setState({
                  ...this.state,
                  checkedKeys: []
                })
              }}
              onError={(err) => {
                console.log('err: ', err);
                message.error("Sorry, something went wrong")
              }}
            >
              {(postSubCatMutation, options) => {
                return (
                  <Spin spinning={loading || options.loading}>
                    <div style={{ marginTop: '20px', }}>
                      <AutoScrollWindow offset={110}>
                        <Button onClick={() => this.disableCategories((variables) => postMutation(variables), (variables) => postSubCatMutation(variables))} disabled={checkedKeys.length === 0 || checkedKeys.filter(data => {
                          try {
                            return JSON.parse(data).is_active === 0
                          } catch (error) {
                            return false
                          }
                        }).length > 0} style={{ margin: 10 }} type="primary">Disable selected</Button>
                        <Button onClick={() => this.enableCategories((variables) => postMutation(variables), (variables) => postSubCatMutation(variables))} disabled={checkedKeys.length === 0 || checkedKeys.filter(data => {
                          try {
                            return JSON.parse(data).is_active === 1
                          } catch (error) {
                            return false
                          }
                        }).length > 0} style={{ margin: 10 }} type="primary">Enable selected</Button>
                        <Tree
                          checkable
                          onExpand={this.onExpand}
                          expandedKeys={expandedKeys}
                          autoExpandParent={autoExpandParent}
                          onCheck={this.onCheck}
                          checkedKeys={checkedKeys}
                          onSelect={(key, e) => {
                            this.edit(JSON.parse(key))
                          }}
                          selectedKeys={selectedKeys}
                        >
                          <TreeNode title='Categories' key='Categories'>
                            {this.renderTreeNodes(categories)}
                          </TreeNode>
                          <TreeNode title='Disabled Categories' key='Disabled Categories'>
                            {this.renderDisabledTreeNodes(categories)}
                          </TreeNode>
                        </Tree>
                      </AutoScrollWindow>
                    </div>
                    <Modal
                      visible={visible}
                      onOk={() => this.handleOk((variables) => postMutation(variables), (variables) => postSubCatMutation(variables))}
                      onCancel={this.handleCancel}
                    >
                      <Form labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal">
                        <Form.Item label="Ticket Category: ">
                          <Input
                            onChange={(event) => {
                              this.setState({
                                ...this.state,
                                edit: {
                                  ...this.state.edit,
                                  title: event.target.value
                                }
                              })
                            }}
                            value={edit.title} />
                        </Form.Item>
                      </Form>
                    </Modal>
                  </Spin>
                )
              }}
            </Mutation>
          )
        }}
      </Mutation>
    );
  }
}
