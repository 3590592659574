import React from 'react'
import { Layout, Menu, Icon, Dropdown } from 'antd';
import './mainpage.css'
import Dashboard from './Dashboard/dashboard';
import Catalog from './Catalog/catalog';
import Tickets from './Tickets/tickets';
import Users from './Users/users';
import Routes from './Settings/Routes/routes';
import LocationCategories from './Settings/LocationCategories/locationCategories';

import _ from 'lodash'

import { setAuthenticated, user } from '../util/AuthRoute'
import { Query } from 'react-apollo';
import { FETCH_USER } from "../../services/graphql/Queries/"

import Error from "../util/Error";
import FetchLoading from "../util/FetchLoading";

import { motion } from "framer-motion";

const loginVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition: { duration: 0.5 } },
  exit: {
    x: '100vw',
    transition: { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }
  }
};




const { Header, Sider, Content } = Layout;

export default class MainPage extends React.Component {
  state = {
    collapsed: false,
    currentTab: '1' //TODO: change back to 1
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleMenuClick = e => {
    this.setState({
      currentTab: e.key,
    });
    localStorage.setItem("CURRENT_TAB", e.key)
  };

  componentDidMount() {
    // this.setState({
    //   ...this.state,
    //   currentTab: _.find(user.permissions, ({ permission_id }) => permission_id === "1") ? "1" : "2"
    // })
  }


  render() {
    // const { currentTab } = this.state;
    const currentTab = localStorage["CURRENT_TAB"] ? localStorage["CURRENT_TAB"] : this.state.currentTab
    return (
      <motion.div
        style={{ width: "100%", }}
        variants={loginVariants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        <Query
          query={FETCH_USER}
          onCompleted={(userData) => {
            console.log('userData: ', userData);
            // if (userData) {
            //   const { firstName, lastName, verification, permissions, location, user_id } = userData.profile
            //   localStorage.setItem("USER_DATA", JSON.stringify({
            //     user_id,
            //     firstName,
            //     lastName,
            //     verification,
            //     permissions: permissions.map(data => parseInt(data.permission_id)),
            //     locationID: location.location_id
            //   }));
            // }
          }}
        >
          {({ loading, error, data }) => {
            if (loading)
              return (<FetchLoading />)
            if (error)
              return (<Error />)
            const { profile } = data
            const { permissions } = profile
            const userPermissions = permissions.map(data => parseInt(data.permission_id))
            return (
              <Layout style={{ height: '100%' }}>
                <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                  <div className="logo" />
                  <Menu
                    onClick={this.handleMenuClick}
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[currentTab]}
                  // defaultOpenKeys={[]}
                  >
                    <Menu.Item key="1">
                      <Icon type="mail" />
                      <span>Tickets</span>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <Icon type="solution" />
                      <span>Customers</span>
                    </Menu.Item>
                    {/* <Menu.Item key="3">
              <Icon type="user" />
              <span>Existing Users</span>
            </Menu.Item> */}
                    {/* <Menu.Item key="4">
              <Icon type="appstore" />
              <span>Catalog</span>
            </Menu.Item> */}
                    {userPermissions.includes(0) && <Menu.SubMenu
                      key="5"
                      onClick={this.handleMenuClick}
                      title={
                        <span>
                          <Icon type="setting" />
                          <span>Settings</span>
                        </span>
                      }
                    >
                      <Menu.Item key="51">Locations & Tickets</Menu.Item>
                      <Menu.Item key="52">Assign Email ID's</Menu.Item>
                      <Menu.Item key="53">Employees</Menu.Item>

                    </Menu.SubMenu>}
                  </Menu>
                </Sider>
                <Layout>
                  <Header style={{ background: '#fff', padding: 0 }}>
                    <Icon
                      className="trigger"
                      type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                      onClick={this.toggle}
                    />
                    <div style={{ float: 'right', paddingRight: '25px' }}>
                      <Dropdown overlay={
                        <Menu>
                          {/* <Menu.Item>
                    <span>
                      <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">
                        <Icon type="user" style={{ paddingRight: '5px' }} />
                        <span>Profile</span>
                      </a>
                    </span>
                  </Menu.Item>
                  <Menu.Item>
                    <span>
                      <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">
                        <Icon type="setting" style={{ paddingRight: '5px' }} />
                        <span>Settings</span>
                      </a>
                    </span>
                  </Menu.Item> */}
                          {/* <Menu.Item>
                          <span>
                            <Icon type="user" style={{ paddingRight: '5px' }} />
                            <span>{`${data.profile.firstname} ${data.profile.lastname}`}</span>
                          </span>
                        </Menu.Item> */}
                          <Menu.Item onClick={() => {
                            setAuthenticated(false, null)
                            localStorage.clear()
                            this.props.history.push('/')
                          }}>
                            <span>
                              <Icon type="logout" style={{ paddingRight: '5px' }} />
                              <span>Logout</span>
                            </span>
                          </Menu.Item>

                        </Menu>
                      }>
                        {
                          // eslint-disable-next-line
                          <a className="ant-dropdown-link">
                            {`${data.profile.firstname} ${data.profile.lastname}`} <Icon type="down" />
                          </a>
                        }

                      </Dropdown>
                    </div>
                  </Header>
                  <Content
                    style={{
                      background: '#fff',
                      height: '100%',
                      // overflowY: 'auto'
                    }}
                  >

                    {currentTab === "1" && <Tickets />}
                    {currentTab === "2" && <Dashboard />}
                    {currentTab === "3" && <Users />}
                    {currentTab === "4" && <Catalog />}
                    {currentTab === "51" && <LocationCategories />}
                    {currentTab === "52" && <Routes />}
                    {currentTab === "53" && <Users />}

                  </Content>
                </Layout>
              </Layout >
            )
          }}
        </Query>
      </motion.div>
    );
  }
}
