import gql from "graphql-tag";

export const TICKET_QUERY = gql`
    query{
        tickets{
            ticket_id,
            ticket_created_at,
            ticket_type,
            ticket_category{
                ticket_category_name,
            },
            ticket_subcategory{
                ticket_sub_category_name,
            },
            ticket_description,
            ticket_is_closed,
            ticket_created_by{
                user_id,
                firstname,
                lastname,
                email,
                location{
                    location_id
                }
            },
            ticket_is_replied,
            files{
                uuid
            }
        }
    }
`;

export const TICKET_CATEGORY_QUERY = gql`
    query ticketCategories($is_active:Int){
        ticketCategories(where:{is_active:$is_active}){
            ticket_category_id
            ticket_category_name
            is_active
            ticket_sub_categories{
                ticket_category_id:ticket_sub_category_id,
                ticket_category_name:ticket_sub_category_name,
                is_active:is_active
            }
        }
    }
`;

export const TICKET_SUBCATEGORY_QUERY = gql`
    query ticketSubCategories($ticket_category:Int,$is_active:Int){
        ticketSubCategories(where:{ticket_category:$ticket_category,is_active:$is_active}){
        ticket_sub_category_id
        ticket_sub_category_name
        ticket_category
        }
    }
`;

export const TICKET_ROUTES_QUERY = gql`
    query ticketRoutes(
        $route_disabled: Int
    ){
        ticketRoutes(where:{route_disabled:$route_disabled}){
            ticket_route_id
            ticket_route_location{
                location_id
                location_name
            }
            ticket_route_category{
                ticket_category_id
                ticket_category_name
            }
            ticket_route_sub_category{
                ticket_sub_category_id
                ticket_sub_category_name
            }
            ticket_route_user{
                user_id
                firstname
                lastname
            }
        }
    }
`;