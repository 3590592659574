import React, { Component } from 'react'

export default class AutoScrollWindow extends Component {
    state = {
        height: 600,
    }

    updateHeight = () => {
        const height = window.innerHeight - this.props.offset;
        this.setState({ height })
    }
    componentDidMount() {
        this.updateHeight();
        window.addEventListener("resize", this.updateHeight);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateHeight);
    }

    render() {
        const { height } = this.state;
        return (
            <div style={{ overflowY: 'auto', height }}>
                {this.props.children}
            </div>
        )
    }
}
