import React, { Component } from 'react'
import { Form, Input, Button, Spin, message } from 'antd';
import { Mutation } from 'react-apollo';
import { CREATE_TICKET_CATEGORY_MUTATION } from '../../../../services/graphql/Mutations/';

import { check } from '../../../../services/FormValidation'
import { TICKET_CATEGORY_QUERY } from '../../../../services/graphql/Queries/'

class AddCategory extends Component {
  state = {
    ticket_category_name: ''
  };


  handleAdd = () => {
    this.setState({ ...this.state, ticket_category_name: '' })
    this.props.form.resetFields()
  }

  render() {
    const { ticket_category_name } = this.state
    const { getFieldDecorator } = this.props.form;
    return (
      <Mutation
        mutation={CREATE_TICKET_CATEGORY_MUTATION}
        variables={{
          ticket_category_name: ticket_category_name,
          is_active: 1
        }}
        refetchQueries={() => {
          return [{ query: TICKET_CATEGORY_QUERY }]
        }}
        onCompleted={() => {
          message.success("Ticket Category has been created successfully")
        }}
        onError={(err) => {
          console.log('err: ', err);
          message.error("Sorry, something went wrong")
        }}
        update={(cache, { data }) => {
          const oldCategoryList = cache.readQuery({ query: TICKET_CATEGORY_QUERY }).ticketCategories
          const oldActiveCategoryList = cache.readQuery({ query: TICKET_CATEGORY_QUERY, variables: { is_active: 1 } }).ticketCategories
          const newCategoryList = [...oldCategoryList, data.createTicketCategory]
          const newActiveCategoryList = [...oldActiveCategoryList, data.createTicketCategory]
          cache.writeQuery({ query: TICKET_CATEGORY_QUERY, data: { ticketCategories: newCategoryList } })
          // cache.writeQuery({ query: TICKET_CATEGORY_QUERY, data: { ticketCategories: newActiveCategoryList } })
          this.handleAdd()
        }}>
        {(postMutation, { loading, error, data, }) => (
          <Spin spinning={loading}>
            <div style={{ margin: '20px 20px', }}>
              <Form>
                <Form.Item label="Category: ">
                  {getFieldDecorator('category', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a category name',
                      },
                    ],
                  })(<Input placeholder="enter category"
                    value={ticket_category_name}
                    onChange={(event) => {
                      this.setState({
                        ...this.state,
                        ticket_category_name: event.target.value
                      })
                    }} />)}
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    xs: { span: 25, offest: 22 },
                    sm: { span: 15, offset: 6 },
                  }}
                >
                  <Button
                    block
                    type="primary"
                    onClick={() => check(this.props.form, () => postMutation())}
                  >
                    Add
                  </Button>
                  {error && <p>Error :( Please try again</p>}
                </Form.Item>
              </Form>
            </div>
          </Spin>
        )}
      </Mutation>
    );
  }
}

export default Form.create({ name: 'addCategory' })(AddCategory);