import gql from "graphql-tag";

export const USERS_QUERY = gql`
    query users($verification:Int){
        users(where:{verification:$verification}){
            user_id
            firstname
            lastname
            designation
            date_created
            company
            address
            phone
            email
            verification
            location{
                location_id
                location_name
            }
            price_type{
                price_name
                price_type_id
            }
            permissions{
                permission_id
                permission_name
                permission_friendly_name
            } 
        }
    }
`;

export const USERS_DROPDOWN = gql`
    query users($verification:Int){
        users(where:{verification:$verification}){
            user_id
            firstname
            lastname
            verification
            permissions{
                permission_id
                permission_name
            }
        }
    }
`;