import gql from "graphql-tag";

export const CREATE_LOCATION = gql`
    mutation createLocation($location_name:String!,$user_id:Int!){
        createLocation(location_name:$location_name,user_id:$user_id)
        {
            location_id
            location_name
        }
    }
`;

export const UPDATE_LOCATION = gql`
    mutation updateLocation($location_id:ID!,$location_name:String){
        updateLocation(location_id:$location_id,location:{location_name:$location_name})
    }
`;

