import { Table, Input, InputNumber, Popconfirm, Form, Button, Spin } from 'antd';
import React from "react";
import { Query, Mutation } from 'react-apollo';
import { LOCATIONS_QUERY } from "../../../../../services/graphql/Queries/"
import { UPDATE_LOCATION } from "../../../../../services/graphql/Mutations/"
import { Loading, Error } from "../../../../util";

const ButtonGroup = Button.Group
const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              initialValue: record[dataIndex],
            })(this.getInput())
            }
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editingKey: '', location_name: '' };
    this.columns = [
      {
        title: 'Location',
        dataIndex: 'location',
        editable: true,
      },
      {
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey, location_name } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Mutation mutation={UPDATE_LOCATION}
                    variables={{
                      location_id: editingKey,
                      location_name: location_name,
                    }}
                    refetchQueries={() => [{ query: LOCATIONS_QUERY }]}
                    update={this.handleUpdate}
                  >
                    {(postMutation, { loading, error, data, }) => (
                      <div>
                        <Spin spinning={loading}>
                          <ButtonGroup style={{ width: "100%" }}>
                            <Button type="primary"
                              onClick={() => {
                                this.save(form, record.key, record, postMutation);
                              }}
                              style={{ width: "50%" }}
                              icon='save'
                            />
                            <Button icon="close-circle" style={{ width: "50%" }} onClick={() => this.cancel(record.key)} />
                          </ButtonGroup>
                          {error && <p>Error :( Please try again</p>}
                        </Spin>
                      </div>
                    )}
                  </Mutation>
                )}
              </EditableContext.Consumer>

            </span>
          ) : (
              <Button style={{}} type="primary" disabled={editingKey !== ''} onClick={() => this.edit(record.key)} icon="edit" />
            );
        },
      },
    ];
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };
  handleUpdate = () => {
    this.setState({ ...this.state, editingKey: '', location_name: '' })

  }
  save(form, key, record, mutate) {

    form.validateFields((error, row) => {
      this.setState({ ...this.state, location_name: row.location }, () => mutate())
      if (error) {
        return;
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <div>

        <EditableContext.Provider value={this.props.form}>
          <Query query={LOCATIONS_QUERY}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return <Error />
              return (
                <Table
                  components={components}
                  dataSource={data.locations.map(data => {
                    return {
                      key: data.location_id,
                      location: data.location_name
                    }
                  })}
                  columns={columns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: this.cancel,
                  }}
                  size="small"
                />
              )
            }}
          </Query>
        </EditableContext.Provider>

      </div>
    );
  }
}

export const EditLocation = Form.create({ name: 'locaton_table' })(EditableTable);

