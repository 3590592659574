import React, { Component } from "react";
import { Tabs, Row, Col } from "antd";
import TicketList from "./ticketList";
import "./tickets.css";
import TicketDescription from "./ticketDescription";
import TicketHistoryList from "./ticketHistoryList";
import TicketHistoryDescription from "./ticketHistoryDescription";
import Error from "../../util/Error";
import Loading from "../../util/FetchLoading";
import _ from "lodash";

import { Query } from "react-apollo";
import { TICKET_QUERY } from "../../../services/graphql/Queries/";

const { TabPane } = Tabs;

export default class Tickets extends Component {
  state = {
    tabPosition: "top",
    selectedTicketID: null,
    selectedTicketHistoryID: null
  };

  renderOpenTickets = data => {
    const { selectedTicketID } = this.state;
    if (!selectedTicketID)
      return (
        <TicketList
          selectedTicketID={selectedTicketID}
          dataSource={data}
          selectOpenTicket={this.setStateProp}
          categories={_.uniq(
            data.map(data => data.ticket_category.ticket_category_name)
          )}
          subCategories={
            data.ticket_subcategory
              ? _.uniq(
                data.map(
                  data => data.ticket_subcategory.ticket_sub_category_name
                )
              )
              : []
          }
          users={_.uniqBy(
            data.map(data => {
              return {
                id: data.ticket_created_by.user_id,
                name: `${data.ticket_created_by.firstname} ${data.ticket_created_by.lastname}`
              };
            }),
            "id"
          )}
        />
      );
    return (
      <Row>
        <Col span={15}>
          <TicketList
            selectedTicketID={selectedTicketID}
            dataSource={data}
            selectOpenTicket={this.setStateProp}
            categories={_.uniq(
              data.map(data => data.ticket_category.ticket_category_name)
            )}
            subCategories={
              data.ticket_subcategory
                ? _.uniq(
                  data.map(
                    data => data.ticket_subcategory.ticket_sub_category_name
                  )
                )
                : []
            }
            users={_.uniqBy(
              data.map(data => {
                return {
                  id: data.ticket_created_by.user_id,
                  name: `${data.ticket_created_by.firstname} ${data.ticket_created_by.lastname}`
                };
              }),
              "id"
            )}
          />
        </Col>
        <Col span={9}>
          <TicketDescription
            selectedTicket={
              data.filter(ticket => ticket.ticket_id === selectedTicketID)[0]
            }
            selectOpenTicket={this.setStateProp}
          />
        </Col>
      </Row>
    );
  };

  renderHistoryTickets = data => {
    const { selectedTicketHistoryID } = this.state;
    if (!selectedTicketHistoryID)
      return (
        <TicketHistoryList
          selectedTicketID={selectedTicketHistoryID}
          dataSource={data}
          selectHistoryTicket={this.setStateProp}
          users={_.uniqBy(
            data.map(data => {
              return {
                id: data.ticket_created_by.user_id,
                name: `${data.ticket_created_by.firstname} ${data.ticket_created_by.lastname}`
              };
            }),
            "id"
          )}
          categories={_.uniq(
            data.map(data => data.ticket_category.ticket_category_name)
          )}
          subCategories={
            data.ticket_subcategory
              ? _.uniq(
                data.map(
                  data => data.ticket_subcategory.ticket_sub_category_name
                )
              )
              : []
          }
        />
      );
    return (
      <Row>
        <Col span={15}>
          <TicketHistoryList
            selectedTicketID={selectedTicketHistoryID}
            dataSource={data}
            selectHistoryTicket={this.setStateProp}
            users={_.uniqBy(
              data.map(data => {
                return {
                  id: data.ticket_created_by.user_id,
                  name: `${data.ticket_created_by.firstname} ${data.ticket_created_by.lastname}`
                };
              }),
              "id"
            )}
            categories={_.uniq(
              data.map(data => data.ticket_category.ticket_category_name)
            )}
            subCategories={
              data.ticket_subcategory
                ? _.uniq(
                  data.map(
                    data => data.ticket_subcategory.ticket_sub_category_name
                  )
                )
                : []
            }
          />
        </Col>
        <Col span={9}>
          <TicketHistoryDescription
            selectedTicket={
              data.filter(
                ticket => ticket.ticket_id === selectedTicketHistoryID
              )[0]
            }
            selectHistoryTicket={this.setStateProp}
          />
        </Col>
      </Row>
    );
  };

  setStateProp = (stateName, value) => {
    this.setState({
      ...this.state,
      [stateName]: value
    });
  };

  render() {
    return (
      <Query
        query={TICKET_QUERY}
        fetchPolicy="cache-and-network"
        pollInterval={30000}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) {
            console.log("tickets error: ", error);
            return <Error />;
          }
          return (
            <Tabs
              type="card"
              tabPosition={this.state.tabPosition}
              tabBarStyle={{ paddingLeft: "10px", marginBottom: "0px" }}
            >
              <TabPane tab="OPEN" key="1">
                {this.renderOpenTickets(
                  data.tickets.filter(ticket => ticket.ticket_is_replied === 0)
                )}
              </TabPane>
              <TabPane tab="HISTORY" key="2">
                {this.renderHistoryTickets(
                  data.tickets.filter(ticket => ticket.ticket_is_replied === 1)
                )}
              </TabPane>
            </Tabs>
          );
        }
        }
      </ Query>
    );
  }
}
