import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwtDecode from 'jwt-decode'
import {
  message
} from "antd";

import { Query } from 'react-apollo';
import gql from "graphql-tag";

import Error from "./Error"
import FetchLoading from "./FetchLoading"

import { FETCH_USER } from '../../services/graphql/Queries/'

import { motion } from "framer-motion"

import { AUTH_TOKEN } from '../../services/constants.js'


let authenticated = false
export let user = null

const loginVariants = {
  exit: {
    opacity: 0
  }
};


export const setAuthenticated = (value, profile) => {
  console.log('value, profile: ', value, profile);

  authenticated = value
  // if (value) {
  user = profile
  // }
  // else {
  //     user = null
  // }
}

export const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <motion.div
      className="loginContainer"
      variants={loginVariants}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      <Query
        query={FETCH_USER}
        fetchPolicy="network-only"
        onCompleted={({ profile }) => {
          if (profile && profile.permissions.find(permission => permission.permission_id === "2"))
            message.error("You are not authorized to login")
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (<FetchLoading />)
          if (error)
            return (<Error />)
          if (data.profile) {
            const { user_id, firstname, lastname, verification, permissions, location, email } = data.profile
            localStorage.setItem("USER_DATA", JSON.stringify({
              user_id,
              firstname,
              lastname,
              verification,
              permissions: permissions.map(data => parseInt(data.permission_id)),
              locationID: location.location_id,
              email
            }))
          }
          const { user_id, firstname, lastname, verification, permissions, location, email } = data.profile
          console.log('data.profile: ', data.profile);
          return (
            <Route
              {...rest}
              render={props => {
                if (localStorage[AUTH_TOKEN] && data.profile && !permissions.find(permission => permission.permission_id === "2")) {
                  message.success(`Welcome ${firstname} ${lastname}!`);
                  return <Component {...props} />
                }
                localStorage.clear()
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
              }}
            />
          )
        }}
      </Query>
    </motion.div>
  )
}