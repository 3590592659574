import React, { useEffect } from "react";

//UI Framework
import { Icon } from "antd";

//Constants
import { AUTH_TOKEN, REMEMBER_ME } from '../../services/constants.js'

//Utils
import { WrappedLoginForm } from "./loginForm";
import "./loginPage.css";
import moment from "moment";
import { motion } from "framer-motion";



const loginVariants = {
  initial: { x: '100vw' },
  enter: { x: 0, transition: { duration: 0.5 } },
  exit: {
    x: '-100vw',
    transition: { duration: 1.5, ease: [0.43, 0.13, 0.23, 0.96] }
  }
};

export const LoginPage = ({ history }, ...props) => {
  useEffect(() => {
    if (localStorage[AUTH_TOKEN] && localStorage[REMEMBER_ME]) {
      history.push("/dashboard");
    }
  }, []);
  return (
    <motion.div
      className="loginContainer"
      variants={loginVariants}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      <div className="loginLeft" />
      <div className="loginRight">
        <div className="logoHeader">Connect UKB</div>
        <div className="loginForm">
          <WrappedLoginForm history={history} />
        </div>
        <div className="footer">
          <div style={{ textAlign: "center" }}>
            Copyright <Icon type="copyright" /> {moment().format("YYYY")} UKB Bagrodia
          </div>
        </div>
      </div>
    </motion.div>
  );
}
