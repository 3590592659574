import React, { Component } from 'react'
import { Form, Input, Button, Select, Upload, Modal, Icon } from 'antd'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default class AddItem extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [
      {
        uid: '-1',
        name: 'xxx.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      },
    ],
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div>
        <Form labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}>
          <Form.Item label="Category:  ">
            <Select>
              <Select.Option value="cat1">Cat1</Select.Option>
              <Select.Option value="cat2">Cat2</Select.Option>
              <Select.Option value="cat3">Cat3</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Item Name: ">
            <Input />
          </Form.Item>
          <Form.Item label="Price: ">
            <Input />
          </Form.Item>
          <Form.Item label="Description: ">
            <Input />
          </Form.Item>
          <Form.Item>
            <Upload
              name="avatar"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
            >
              {fileList.length >= 3 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Form.Item>
          <Button block type="primary" style={{ float: "right" }}>Add </Button>
        </Form>
      </div>
    )
  }
}
