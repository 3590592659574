import gql from "graphql-tag";
const USERS_QUERY = gql`
      query users($verification:Int){
        users(where:{verification:$verification}){
          user_id
          firstname
          lastname
          designation
          date_created
          company
          address
          phone
          email
          verification
          location{
            location_id,
            location_name
          }
          price_type{
            price_name,
            price_type_id
          }
          permissions{
            permission_id
            permission_name
          }
        }
      }
      
      `;
const PERMISSIONS_QUERY = gql`
       query permissions{
         permissions{
           permission_id
           permission_name
           permission_friendly_name
         }
       }
       
       `;
const UPDATE_USER_MUTATION = gql`
mutation updateUser(
  $user_id:Int!,
  $location:Int,
  $price_type:Int,
  $verification:Int,
  $firstname: String,
  $lastname: String,
  $designation: String,
  $company: String,
  $address: String,
  $phone: String,
  $email: String,
  $addUserPermissions: [Int],
  $deleteUserPermssions: [Int]
  ){
  updateUser(
    user_id:$user_id,
    user:{
      location:$location,
      price_type:$price_type,
      verification:$verification,
      firstname:$firstname,
      lastname:$lastname,
      designation:$designation,
      company:$company,
      address:$address,
      phone:$phone,
      email:$email
    }
    addUserPermissions:$addUserPermissions
    deleteUserPermissions:$deleteUserPermssions
  ){
        user_id
        firstname
        lastname
        designation
        date_created
        company
        address
        phone
        email
        verification
        location{
          location_id,
          location_name
        }
        price_type{
          price_name,
          price_type_id
        }
        permissions{
          permission_id
          permission_name
        }
  }
}

`;


const REJECT_USER_MUTATION = gql`
mutation updateUser($user_id:Int!,$verification:Int){
  updateUser(user_id:$user_id,user:{verification:$verification})
}

`;
export {
  USERS_QUERY,
  PERMISSIONS_QUERY,
  UPDATE_USER_MUTATION,
  REJECT_USER_MUTATION,
}
