import React, { Component } from 'react'
import {
  Form, Input, Button, Select, Spin, Icon, message
} from 'antd';

import { check } from '../../../../services/FormValidation'

import { Mutation } from 'react-apollo';
import { TICKET_CATEGORY_QUERY, TICKET_SUBCATEGORY_QUERY } from '../../../../services/graphql/Queries/'
import { CREATE_TICKET_SUBCATEGORY_MUTATION } from '../../../../services/graphql/Mutations/';

let id = 0
class AddSubCategory extends Component {
  state = {
    value: 0,
    ticket_category: 0,
    ticket_category_name: '',
    ticket_sub_category_name: '',
    ticket_subCategoryList: [{ id: 0, name: '' }]
  };

  handleAdd = () => {
    this.setState({
      ...this.state,
      ticket_sub_category_name: '',
      ticket_category: 0,
      ticket_category_name: ''
    })
  }

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
    this.setState({
      ...this.state,
      ticket_subCategoryList: this.state.ticket_subCategoryList.filter(({ id }) => id !== (k + 1))
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });

    this.setState({
      ...this.state,
      ticket_subCategoryList: [...this.state.ticket_subCategoryList, { id, name: '' }]
    });
  };

  render() {
    const { ticket_category, ticket_sub_category_name, ticket_category_name, ticket_subCategoryList } = this.state
    console.log('ticket_subCategoryList: ', ticket_subCategoryList);
    const { categories, form } = this.props
    const { getFieldDecorator, getFieldValue } = form;
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form.Item
        required={false}
        key={k}

      >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            {getFieldDecorator(`names[${k}]`, {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: "Please input subcategory's name or delete this field.",
                },
              ],
            })(<Input
              placeholder="enter sub-category"
              style={{ marginRight: '8px' }}
              onChange={(event) => {
                this.setState({
                  ...this.state,
                  ticket_subCategoryList: ticket_subCategoryList.map(data => {
                    if (data.id === (k + 1))
                      return {
                        ...data,
                        name: event.target.value
                      }
                    return data
                  })
                })
              }}
            />)}
          </div>
          <div style={{ padding: '0px 10px' }}>
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => this.remove(k)}
            />
          </div>
        </div>
      </Form.Item>
    ))
    return (
      <Mutation
        mutation={CREATE_TICKET_SUBCATEGORY_MUTATION}
        onCompleted={() => {
          message.success("Ticket Sub-Category has been created successfully")
        }}
        onError={(err) => {
          console.log('err: ', err);
          message.error("Sorry, something went wrong")
        }}
        refetchQueries={() => {
          return [{ query: TICKET_CATEGORY_QUERY }]
        }}
        update={(cache, { data }) => {
          const { createTicketSubCategory } = data
          const { ticket_category, ticket_sub_category_id, ticket_sub_category_name, __typename, is_active } = createTicketSubCategory
          const oldCategoryList = cache.readQuery({ query: TICKET_CATEGORY_QUERY }).ticketCategories
          const newCategoryList = oldCategoryList.map((data) => {
            const { ticket_category_id, ticket_sub_categories } = data
            if (parseInt(ticket_category_id) === ticket_category) {
              return {
                ...data,
                ticket_sub_categories: [
                  ...ticket_sub_categories,
                  {
                    ticket_category_id: ticket_sub_category_id,
                    is_active,
                    ticket_category_name: ticket_sub_category_name,
                    __typename
                  }
                ]
              }
            }
            else
              return data
          })
          cache.writeQuery({ query: TICKET_CATEGORY_QUERY, data: { ticketCategories: newCategoryList } })
          this.handleAdd()
        }}
      >
        {(postMutation, { loading, error, value, }) => (
          <Spin spinning={loading}>
            <div style={{ margin: '20px 20px' }}>
              <Form>
                <Form.Item label="Category ">
                  {getFieldDecorator('category', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a category',
                      },
                    ],
                  })(<Select
                    value={ticket_category_name}
                    onChange={(value, key) => {
                      console.log({ value })
                      this.setState({
                        ...this.state,
                        ticket_category_name: value,
                        ticket_category: key.key
                      })

                    }}>
                    {categories.ticketCategories.filter(({ is_active }) => is_active === 1).map(category => {
                      return (
                        <Select.Option
                          key={category.ticket_category_id}
                          value={category.ticket_category_name}
                        >
                          {category.ticket_category_name}
                        </Select.Option>
                      )
                    })}

                  </Select>)}
                </Form.Item>

                <Form.Item label="Sub-Category: ">
                  {getFieldDecorator('subCategory', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a sub category name',
                      },
                    ],
                  })(<Input placeholder="enter sub-category"
                    value={ticket_subCategoryList[0].name}
                    onChange={(event) => {
                      this.setState({
                        ...this.state,
                        ticket_sub_category_name: event.target.value,
                        ticket_subCategoryList: ticket_subCategoryList.map(data => {
                          if (data.id === 0)
                            return {
                              ...data,
                              name: event.target.value
                            }
                          return data
                        })
                      })
                    }} />)}
                </Form.Item>

                {formItems}

                <Form.Item>
                  <Button block type="dashed" onClick={this.add}>
                    <Icon type="plus" /> Add sub-category field
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button block type="primary"
                    onClick={() => {
                      form.validateFields(async err => {
                        if (!err) { //TODO: Test this
                          await ticket_subCategoryList.forEach(({ name }) => {
                            postMutation({
                              variables: {
                                ticket_sub_category_name: name,
                                ticket_category: parseInt(ticket_category),
                                is_active: 1
                              }
                            })
                          })
                          this.props.form.resetFields()
                        }
                      })
                    }}>Add </Button>
                  {error && <p>Error! Please try again</p>}
                </Form.Item>

              </Form>
            </div>
          </Spin>
        )
        }
      </Mutation>
    );
  }
}

export default Form.create({ name: 'addSubCategory' })(AddSubCategory);