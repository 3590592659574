import gql from "graphql-tag";

export const FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String) {
        forgotPassword(email: $email)
    }
`;

export const LOGIN_USER = gql`
    mutation loginUser($email:String!, $password:String!){
        loginUser(
            email:$email,
            password:$password
        )
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($token: String!, $password: String!) {
        resetPassword(token: $token, password: $password)
    }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
    mutation changePassword(
        $email:String!,
        $new_password:String!,
        $old_passord:String!
    ){
        changePassword(
            email:$email,
            new_password:$new_password,
            old_passord:$old_passord
        )
    }
`;