import React, { Component } from 'react'
import { Descriptions, Typography, Icon, Button, Avatar } from 'antd'

import { Query } from 'react-apollo';
import { IMAGE_URL_QUERY } from '../../../services/graphql/Queries/'

import moment from 'moment'

export default class TicketHistoryDescription extends Component {
    render() {
        const { selectedTicket, selectHistoryTicket } = this.props
        console.log('selectedTicket: ', selectedTicket);
        const { ticket_id, ticket_created_at, ticket_subcategory, ticket_category, ticket_created_by, subCategory, ticket_description, files } = selectedTicket
        const { firstname, lastname, user_id } = ticket_created_by
        return (
            <div className="ticketDescription">
                <div style={{ display: 'flex', borderBottom: 'solid 1px #d9d9d9' }}>
                    <Typography.Title style={{ padding: '12px 18px', margin: 0, flex: 1 }} level={4}>Ticket ID {ticket_id}</Typography.Title>
                    <Button type="danger" ghost style={{ margin: '8px 5px 0px 0px' }} icon="close" onClick={() => selectHistoryTicket("selectedTicketHistoryID", null)} />
                </div>
                <Descriptions
                    bordered
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    layout="vertical"
                >
                    <Descriptions.Item label="Name">{`${firstname} ${lastname}`}</Descriptions.Item>
                    <Descriptions.Item label="Date">{moment(parseInt(ticket_created_at)).format('LLL')}</Descriptions.Item>
                    <Descriptions.Item label="Category">{ticket_category.ticket_category_name}</Descriptions.Item>
                    <Descriptions.Item label="Sub Category">{ticket_subcategory.ticket_sub_category_name}</Descriptions.Item>
                    <Descriptions.Item label="Description">{ticket_description}</Descriptions.Item>
                </Descriptions>
                {files.length > 0 && <div>
                    <div><Icon type="file-text" style={{ padding: '10px 10px' }} /><Typography.Text strong>Attachments</Typography.Text></div>
                    <div style={{ margin: '10px', display: 'flex', flexWrap: 'wrap', }}>
                        {
                            files.map(file => (
                                <Query
                                    query={IMAGE_URL_QUERY}
                                    variables={{
                                        type: "DOWNLOAD", filename: file.uuid
                                    }}
                                >
                                    {({ loading, error, data }) => {
                                        if (loading) return (
                                            <Avatar shape="square" size={128} style={{ margin: '5px', border: '1px solid gray' }} icon={<Icon type="loading" />} />
                                        )
                                        if (error) return (
                                            <Avatar shape="square" size={128} style={{ margin: '5px', border: '1px solid gray' }} icon={<Icon type="file-unknown" />} />
                                        )
                                        return (
                                            <div>
                                                <Avatar shape="square" size={128} style={{ margin: '5px', border: '1px solid gray' }} src={data.imageURL} />
                                            </div>
                                        )
                                    }}
                                </Query>
                            ))
                        }
                    </div>
                </div>}

            </div>
        )
    }
}
