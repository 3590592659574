import React, { Component } from 'react'
import { Spin, Icon } from 'antd'

export default class FetchLoading extends Component {
    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', width: '100vw', alignItems: 'center', flexDirection: 'column' }}>
                <Spin size="large" tip={this.props.title} />
            </div>
        )
    }
}
