import React, { Component, useState } from 'react'
import './dashboard.css'
import { Button, Select, Spin, Form, Table, Input, Tooltip, Popconfirm, message, Tag } from 'antd';

import { Query, Mutation } from 'react-apollo';
import {
    UPDATE_USER_MUTATION,
} from '../../../services/graphql/Mutations/'
import {
    USERS_QUERY,
    LOCATIONS_QUERY,
    PRICE_QUERY,
} from '../../../services/graphql/Queries/'

import { ResizeableTitle } from '../../util/ResizableTable'

import _ from 'lodash';

const ButtonGroup = Button.Group
const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.dataIndex === 'price_type') {
            return (
                <Query query={PRICE_QUERY}>
                    {({ loading, error, data }) => {
                        if (loading) return <Spin />
                        if (error) return <div>Error</div>
                        return (
                            <Select
                                onChange={(value, key) => {
                                    this.props.editRow("price_type_id", key.key)
                                    setTimeout(() => {
                                        this.props.editRow("price_type", key.props.value)
                                    }, 2000);
                                }}
                                style={{ width: "100%" }}
                            >
                                {data.pricetypes.map(({ price_type_id, price_name }) => (
                                    <Select.Option
                                        key={price_type_id}
                                        value={price_type_id}>
                                        {price_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )
                    }}
                </Query>
            )
        }
        else if (this.props.dataIndex === 'location') {
            return (
                <Query query={LOCATIONS_QUERY}>
                    {({ loading, error, data }) => {
                        if (loading) return <Spin />
                        if (error) return <div>Error</div>
                        return (
                            <Select
                                showSearch
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                optionFilterProp="children"
                                onChange={(value, key) => {
                                    console.log('value, key: ', value, key);
                                    this.props.editRow("location_id", key.key)
                                    setTimeout(() => {
                                        this.props.editRow("location", key.props.value)
                                    }, 2000);
                                }}
                                style={{ width: "100%" }}
                            >
                                {data.locations.filter(({ location_name }) => location_name).map(({ location_id, location_name }) => (
                                    <Select.Option
                                        key={location_id}
                                        value={location_id}>
                                        {location_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )
                    }}
                </Query>
            )
        }
        else
            return <Input
                onChange={(event) => {
                    this.props.editRow(this.props.dataIndex, event.target.value)
                }}
            />
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            // rules: [
                            //     {
                            //         required: true,
                            //         message: `Please Input ${title}!`,
                            //     },
                            // ],
                            initialValue: record[dataIndex],
                        })(
                            this.getInput()
                        )}
                    </Form.Item>
                ) : (
                        children
                    )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class userTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            columns: props.columns,
            dataSource: props.dataSource,
            reloadSpinner: false,
            editingKey: '',
            editingRecord: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.setState({
                ...this.setState,
                dataSource: nextProps.dataSource
            })
        })
    }

    isEditing = record => record.key === this.state.editingKey;

    componentDidMount() {
        const { name, columns } = this.props
        if (name === "pendingUser") {
            this.setState({
                ...this.state,
                columns: [
                    ...columns,
                    {
                        width: 100,
                        title: 'Price Type',
                        dataIndex: 'priceType',
                        render: (text, record) => {
                            return (<Query query={PRICE_QUERY}>
                                {({ loading, error, data }) => {
                                    if (loading) return <Spin />
                                    if (error) return <div>Error</div>
                                    return (
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            optionFilterProp="children"
                                            onChange={(value, key) => {
                                                this.setState({
                                                    ...this.state,
                                                    dataSource: this.state.dataSource.map(data =>
                                                        data.user_id === record.user_id ? {
                                                            ...data,
                                                            "priceType": key.key
                                                        } : data
                                                    )
                                                })
                                            }}
                                            style={{ width: "100%" }}
                                        >
                                            {data.pricetypes.map(({ price_type_id, price_name }) => (
                                                <Select.Option
                                                    key={price_type_id}
                                                    value={price_type_id}>
                                                    {price_name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )
                                }}
                            </Query>)
                        }
                    },
                    {
                        width: 100,
                        title: 'Location',
                        dataIndex: 'location',
                        render: (text, record) => {
                            return (
                                <Query query={LOCATIONS_QUERY}>
                                    {({ loading, error, data }) => {
                                        if (loading) return <Spin />
                                        if (error) return <div>error</div>
                                        return (
                                            <Select
                                                showSearch
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                optionFilterProp="children"
                                                onChange={(value, key) => {
                                                    this.setState({
                                                        ...this.state,
                                                        dataSource: this.state.dataSource.map(data =>
                                                            data.user_id === record.user_id ? {
                                                                ...data,
                                                                "location": key.key
                                                            } : data
                                                        )
                                                    })
                                                }}
                                                style={{ width: "100%" }}
                                            >
                                                {data.locations.filter(({ location_name }) => location_name).map(({ location_id, location_name }) => (
                                                    <Select.Option
                                                        key={location_id}
                                                        value={location_id}>
                                                        {location_name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )
                                    }
                                    }
                                </Query >
                            )
                        }
                    },
                    {
                        width: 100,
                        title: 'Accept/Reject',
                        dataIndex: 'view',
                        render: (text, record) => {
                            return (
                                <Mutation
                                    mutation={UPDATE_USER_MUTATION}
                                    refetchQueries={() => {
                                        return [{ query: USERS_QUERY, variables: { verification: 0 } }]
                                    }}
                                    update={(cache, { data }) => {
                                        const oldUserList = cache.readQuery({ query: USERS_QUERY, variables: { verification: 0 } }).users
                                        const newUserList = oldUserList.filter(({ user_id }) => user_id !== record.user_id)
                                        cache.writeQuery({ query: USERS_QUERY, variables: { verification: 0 }, data: { users: newUserList } })
                                    }}
                                    onCompleted={data => {
                                        message.success('Table successfully modified');
                                        this.setState({
                                            ...this.state,
                                            reloadSpinner: false
                                        })
                                    }}
                                    onError={err => {
                                        console.log('mutation err: ', err);
                                        message.error("Sorry, something went wrong")
                                        this.setState({
                                            ...this.state,
                                            reloadSpinner: false
                                        })
                                    }}
                                >
                                    {(postMutation, { loading, error, data }) => {
                                        if (loading) return <Spin />
                                        if (error) return <div>Error</div>
                                        return (
                                            <ButtonGroup style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Tooltip placement="topLeft" title={!(record.priceType && record.location) ? "Need to set price type and location" : "Accept User"} >
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({
                                                                ...this.state,
                                                                reloadSpinner: true
                                                            })
                                                            postMutation({
                                                                variables: {
                                                                    user_id: parseInt(record.user_id),
                                                                    location: parseInt(record.location),
                                                                    price_type: parseInt(record.priceType),
                                                                    verification: 4,
                                                                    addUserPermissions: [2],
                                                                    deleteUserPermssions: [0]
                                                                }
                                                            })
                                                        }}
                                                        disabled={!(record.priceType && record.location)}
                                                        type="primary"
                                                        icon="check-circle" />
                                                </Tooltip>
                                                <Tooltip placement="topRight" title={"Reject User"} >
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({
                                                                ...this.state,
                                                                reloadSpinner: true
                                                            })
                                                            postMutation({
                                                                variables: {
                                                                    user_id: parseInt(record.user_id),
                                                                    location: parseInt(record.location),
                                                                    price_type: parseInt(record.priceType),
                                                                    verification: 2
                                                                }
                                                            })
                                                        }
                                                        }
                                                        type="danger"
                                                        icon="close-circle" />
                                                </Tooltip>
                                            </ButtonGroup>
                                        )
                                    }}
                                </Mutation>
                            )
                        }
                    }
                ]
            })
        }
        else if (name === "existingUser") {
            this.setState({
                ...this.state,
                columns: [
                    ...columns,
                    {
                        width: 100,
                        title: 'Price Type',
                        dataIndex: 'price_type',
                        editable: true,
                    },
                    {
                        width: 100,
                        title: 'Location',
                        dataIndex: 'location',
                        filters: this.props.locations.map(location => ({
                            text: location,
                            value: location
                        })),
                        onFilter: (value, record) => record.location.indexOf(value) === 0,
                        editable: true
                    },
                    {
                        width: 100,
                        title: 'Status',
                        dataIndex: 'verification',
                        render: (text, record) => (
                            <Tag color={record.verification === 4 ? "geekblue" : "volcano"} key={record.verification}>
                                {record.verification === 4 ? `Enabled` : `Disabled`}
                            </Tag>
                        ),
                        onFilter: (value, record) => record.location.indexOf(value) === 0,
                    },
                    {
                        width: 100,
                        dataIndex: 'editDelete',
                        render: (text, record) => {
                            const editable = this.isEditing(record);
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: 'center'
                                    }}
                                >
                                    {
                                        editable ? (
                                            <Mutation
                                                mutation={UPDATE_USER_MUTATION}
                                                refetchQueries={() => {
                                                    return [{ query: USERS_QUERY, variables: { verification: 4 } }, { query: USERS_QUERY }]
                                                }}
                                                update={(cache, { data }) => {

                                                    if (data.updateUser.verification <= 2) {
                                                        const oldEmployeeList = cache.readQuery({ query: USERS_QUERY, variables: { verification: 4 } }).users
                                                        const newEmployeeList = oldEmployeeList.filter(value => value.user_id !== record.user_id)
                                                        cache.writeQuery({ query: USERS_QUERY, variables: { verification: 4 }, data: { users: newEmployeeList } })
                                                    }
                                                    else {
                                                        try {
                                                            const oldEmployeeList = cache.readQuery({ query: USERS_QUERY, variables: { verification: 4 } }).users
                                                            const newEmployeeList = oldEmployeeList.map((value) => {
                                                                if (value.user_id === record.user_id)
                                                                    return data.updateUser
                                                                return value
                                                            })
                                                            cache.writeQuery({ query: USERS_QUERY, variables: { verification: 4 }, data: { users: newEmployeeList } })
                                                        } catch (err) {

                                                        }

                                                        try {
                                                            const oldUserList = cache.readQuery({ query: USERS_QUERY }).users
                                                            const newUserList = oldUserList.map((value) => {
                                                                if (value.user_id === record.user_id)
                                                                    return data.updateUser
                                                                return value
                                                            })
                                                            cache.writeQuery({ query: USERS_QUERY, data: { users: newUserList } })
                                                        }
                                                        catch (err) {

                                                        }
                                                    }


                                                }}
                                                onCompleted={data => {
                                                    this.cancel()
                                                    setTimeout(() => {
                                                        this.setState({
                                                            ...this.state,
                                                            reloadSpinner: false
                                                        })
                                                        message.success('Table successfully modified');
                                                    }, 2000);
                                                }}
                                                onError={err => {
                                                    console.log('mutation err: ', err);
                                                    message.error("Sorry, something went wrong")
                                                    this.setState({
                                                        ...this.state,
                                                        reloadSpinner: false
                                                    })
                                                }}
                                            >
                                                {(postMutation, { loading, error, data }) => {
                                                    if (error) return <div>Error</div>
                                                    const { user_id, location_id, price_type_id, } = record

                                                    return (
                                                        <ButtonGroup>
                                                            <Tooltip title={_.isEqual(this.state.editingRecord, record) ? "No changes" : "Save Changes"}>
                                                                <Button
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            reloadSpinner: true
                                                                        })
                                                                        postMutation({
                                                                            variables: {
                                                                                ...this.state.editingRecord,
                                                                                user_id: parseInt(user_id),
                                                                                location: parseInt(this.state.editingRecord.location_id),
                                                                                price_type: parseInt(this.state.editingRecord.price_type_id),
                                                                            }
                                                                        })
                                                                    }}
                                                                    disabled={_.isEqual(this.state.editingRecord, record)}
                                                                    type="primary"
                                                                    icon="save"
                                                                    loading={loading}
                                                                />
                                                            </Tooltip>
                                                            <Tooltip title={record.verification === 4 ? "Disable User" : "Enable User"}>
                                                                <Popconfirm
                                                                    title={record.verification === 4 ? "Are you sure you want to disable this user?" : "Are you sure you want to enable this user?"}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                    onConfirm={() => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            reloadSpinner: true
                                                                        })
                                                                        record.verification === 4 ? postMutation({
                                                                            variables: {
                                                                                ...this.state.editingRecord,
                                                                                user_id: parseInt(user_id),
                                                                                location: parseInt(this.state.editingRecord.location_id),
                                                                                price_type: parseInt(this.state.editingRecord.price_type_id),
                                                                                verification: 0
                                                                            }
                                                                        }) :
                                                                            postMutation({
                                                                                variables: {
                                                                                    ...this.state.editingRecord,
                                                                                    user_id: parseInt(user_id),
                                                                                    location: parseInt(this.state.editingRecord.location_id),
                                                                                    price_type: parseInt(this.state.editingRecord.price_type_id),
                                                                                    verification: 4
                                                                                }
                                                                            })
                                                                    }}
                                                                >
                                                                    <Button
                                                                        style={{ backgroundColor: `${record.verification === 4 ? "#fa8c16" : "#4CAF50"}`, color: "#FFF" }}
                                                                        icon={record.verification === 4 ? "minus-circle" : "check-circle"}
                                                                        loading={loading}
                                                                    />
                                                                </Popconfirm>
                                                            </Tooltip>
                                                            <Tooltip title="Cancel">
                                                                <Button
                                                                    onClick={() => {
                                                                        this.cancel()
                                                                    }}
                                                                    type="danger"
                                                                    icon="close-circle" />
                                                            </Tooltip>
                                                        </ButtonGroup>
                                                    )
                                                }
                                                }
                                            </Mutation >
                                        ) : (
                                                <ButtonGroup>
                                                    <Tooltip title={"Edit"}>
                                                        <Button
                                                            onClick={() => {
                                                                this.edit(record.key, record)
                                                            }}
                                                            type="primary"
                                                            icon="edit"
                                                        />
                                                    </Tooltip>
                                                    {/* <Tooltip title="Disable">
                                                        <Button
                                                            onClick={() => {
                                                                console.log(`Add disable functionality`)
                                                            }}
                                                            disabled={true}
                                                            type="danger"
                                                            icon="minus-circle"
                                                        />
                                                    </Tooltip> */}
                                                </ButtonGroup>
                                            )
                                    }
                                </div>
                            )
                        }
                    }
                ]
            })
        }
        else {
            this.setState({
                ...this.state,
                columns: [
                    ...columns,
                    {
                        width: 100,
                        dataIndex: 'editDelete',
                        render: (text, record) => {
                            return (
                                <Mutation
                                    mutation={UPDATE_USER_MUTATION}
                                    update={(cache, { data }) => {
                                        const oldUserList = cache.readQuery({ query: USERS_QUERY, variables: { verification: 4 } }).users
                                        const newUserList = oldUserList.map((value) => {
                                            if (value.user_id === record.user_id)
                                                return data.updateUser
                                            return value
                                        })
                                        cache.writeQuery({ query: USERS_QUERY, data: { users: newUserList } })
                                    }}
                                    refetchQueries={() => {
                                        return [{ query: USERS_QUERY }, { query: USERS_QUERY, variables: { verification: 4 } }]
                                    }}
                                    onCompleted={data => {
                                        message.success('Table successfully modified');
                                        this.setState({
                                            ...this.state,
                                            reloadSpinner: false
                                        })
                                    }}
                                    onError={err => {
                                        console.log('mutation err: ', err);
                                        message.error("Sorry, something went wrong")
                                        this.setState({
                                            ...this.state,
                                            reloadSpinner: false
                                        })
                                    }}
                                >
                                    {(postMutation, { loading, error, data }) => {
                                        if (loading) return <div>Fetching</div>
                                        if (error) return <div>Error</div>
                                        const { user_id, location_id, price_type_id, } = record
                                        return (
                                            <ButtonGroup style={{ width: '100%', }}>
                                                <Button
                                                    style={{ width: '50%', }}
                                                    onClick={() => {
                                                        this.props.action(record)
                                                        this.edit(record.key, record)
                                                        console.log('record.key: ', record.key);
                                                    }}
                                                    type="primary"
                                                    icon="edit" />
                                                <Button
                                                    style={{ width: '50%' }}
                                                    onClick={() => {
                                                        this.cancel()
                                                        this.setState({
                                                            ...this.state,
                                                            reloadSpinner: true
                                                        })
                                                        postMutation({
                                                            variables: {
                                                                user_id: parseInt(user_id),
                                                                location: parseInt(location_id),
                                                                price_type: parseInt(price_type_id),
                                                                verification: 2
                                                            }
                                                        })
                                                    }
                                                    }
                                                    type="danger"
                                                    icon="close-circle" />
                                            </ButtonGroup>
                                        )
                                    }}
                                </Mutation>
                            )
                        }
                    }
                ]
            })
        }
    }


    edit(key, record) {
        this.setState({
            ...this.state,
            editingKey: key,
            editingRecord: record
        });
    }

    cancel = () => {
        this.setState({
            ...this.state,
            editingKey: '',
            editingRecord: {}
        });
    };

    components = {
        header: {
            cell: ResizeableTitle,
        },
        body: {
            cell: EditableCell,
        },
    };

    handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return { columns: nextColumns };
        });
    };

    render() {
        const { dataSource, reloadSpinner } = this.state
        const columns = this.state.columns.map((col, index) => ({

            ...col,
            onHeaderCell: column => ({
                width: column.width,
                onResize: this.handleResize(index),
            }),
        }));
        return (
            <Spin spinning={reloadSpinner}>
                <EditableContext.Provider value={this.props.form}>
                    {/* <AutoScrollWindow> */}
                    <Table
                        rowKey={record => record.user_id}
                        components={this.components}
                        bordered
                        columns={columns.map((col, index) => {
                            if (!col.editable) {
                                return col
                            }
                            return {
                                ...col,
                                onCell: record => ({
                                    record,
                                    inputType: col.dataIndex === 'location' || 'price_type' ? 'dropdown' : 'text',
                                    dataIndex: col.dataIndex,
                                    title: col.title,
                                    editRow: (prop, value) => {
                                        this.setState({
                                            ...this.state,
                                            editingRecord: {
                                                ...this.state.editingRecord,
                                                [prop]: value
                                            }
                                        })
                                    },
                                    editing: this.isEditing(record),
                                }),
                            };
                        })}
                        dataSource={dataSource}
                        size="small"
                        scroll={{ x: window.innerWidth * 0.50, y: window.innerHeight * 0.65 }}
                    // bordered
                    />
                    {/* </AutoScrollWindow> */}
                </EditableContext.Provider>
            </Spin>
        )
    }
}

export default Form.create()(userTable);
