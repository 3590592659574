import React, { Component } from 'react'
import {
  Row, Col, Card, Typography, Radio,
  PageHeader, Button, Input, Icon, Modal,
  Form
} from 'antd'

import './catalogBrowser.css'
import AutoScrollWindow from '../../../util/AutoScrollWindow';
import AddCategory from '../addCategory';
import AddItem from '../addItem';
// import Paragraph from 'antd/lib/skeleton/Paragraph';
const { Paragraph, Text } = Typography;
export default class CatalogBrowser extends Component {
  state = {
    itemVisible: false,
    radioValue: 1,
    modalVisible: false,
    location: "Categories",
    modalID: 0,
    addModal: false,
    categories: [
      {
        id: 1,
        name: "Category 1",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 2,
        name: "Category 2",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 3,
        name: "Category 3",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 4,
        name: "Category 4",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 5,
        name: "Category 5",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 6,
        name: "Category 6",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 7,
        name: "Category 7",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 8,
        name: "Category 8",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 9,
        name: "Category 9",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 10,
        name: "Category 10",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 11,
        name: "Category 11",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 12,
        name: "Category 12",
        img: "https://via.placeholder.com/150"
      },

    ],
    itemCategories: [
      {
        id: 1,
        name: "Category 1",
        img: "https://via.placeholder.com/150"
      },
      {
        id: 2,
        name: "Category 2",
        img: "https://via.placeholder.com/150"
      },
    ]
  }
  showItems = (id) => {
    console.log({ id })
    this.setState({ ...this.state, itemVisible: true })
  }
  showModal = (checkedStatus) => {
    this.setState({ ...this.state, modalVisible: true, modalID: checkedStatus })
  }
  handleOK = e => {
    console.log(e);
    this.setState({
      ...this.state,
      modalVisible: false,
    });
  };
  onRadioChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      radioValue: e.target.value,
    });
  };
  renderCategories = (categories) => {
    const cols = categories.map((item) => {
      const col = <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 4 }} className="gutterBox category-card">
        <Card
          hoverable
          style={styles.cardStyle}
          cover={<img alt="example" src={item.img} />}
          bodyStyle={styles.bodyStyle}
          onClick={() => this.showItems(item.id)}
        >
          <Typography.Title level={4} style={{ marginRight: '5px' }} className="mb-0">Category 1</Typography.Title>
          <Button size="small" icon="edit" />
        </Card>

      </Col>
      return col
    })

    return cols
  }
  renderItemCategories = (itemCategories) => {
    const cols = itemCategories.map((item) => {
      const col = <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 4 }} className="gutterBox category-card">
        <Card
          hoverable
          style={styles.cardStyle}
          cover={<img alt="example" src={item.img} />}
          bodyStyle={styles.bodyStyle}
          onClick={() => this.showModal(item.id)}
        >
          <Typography.Title level={4} style={{ marginRight: '5px' }} className="mb-0">Category 1</Typography.Title>
          <Button size="small" icon="edit" />
        </Card>

      </Col>
      return col
    })

    return cols
  }
  render() {
    const { location, categories, itemVisible,
      itemCategories, modalVisible, addModal, radioValue } = this.state;
    return (
      <div style={{ height: '100%' }}>
        {!itemVisible ? (
          <div>
            <PageHeader
              onBack={() => null}
              title={location}
              extra={[
                <Input prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Search Categories" allowClear />
              ]} />

            <Button type="primary" onClick={() => {
              this.setState({
                ...this.state, addModal: true
              })
            }}>Add</Button>
            <AutoScrollWindow offset={140}>
              <Row gutter={8} style={{
                height: '100%',
                display: 'flex',
                flexWrap: 'wrap'
              }}>
                {this.renderCategories(categories)}

              </Row>
            </AutoScrollWindow>
          </div>) :
          (
            <div>
              <PageHeader onBack={() => null} title="Items" extra={[
                <Input prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Search Item-Categories" allowClear />
              ]} />
              <AutoScrollWindow offset={140}>
                <Row gutter={8} style={{
                  height: '100%',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}>
                  {this.renderItemCategories(itemCategories)}

                </Row>
              </AutoScrollWindow>
            </div>)}
        <Modal
          title="Item Details"
          visible={modalVisible}
          onOk={this.handleOK}
          onCancel={this.handleOK}
        >
          <Form labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal">
            <Form.Item label="Name: ">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Designation: ">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Address: ">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Phone: ">
              <Input readOnly />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          style={{ top: 20 }}
          title="Add Catalog"
          visible={addModal}
          onCancel={() => { this.setState({ ...this.state, addModal: false }) }}
          onOk={() => { this.setState({ ...this.state, addModal: false }) }}
        >
          <div>
            <Radio.Group onChange={this.onRadioChange}
              value={radioValue}
              style={{ marginBottom: "25px" }}>
              <Radio value={1}>Add category</Radio>
              <Radio value={2}>Add Item</Radio>
            </Radio.Group>
            {(radioValue === 1) ? (<AddCategory />) : (<AddItem />)}
          </div>



        </Modal>

      </div >
    )
  }
}


const styles = {
  cardStyle: {
    width: 150,
    margin: 'auto'
  },
  bodyStyle: {
    display: 'flex',
    padding: '5px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'baseline'
  }
}
