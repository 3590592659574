import React, { Component } from 'react'
import { Form, Button, Input, Icon, Typography, Select, Spin } from 'antd'

import { Query } from 'react-apollo';
import { Mutation } from 'react-apollo';
import {
    USERS_DROPDOWN,
    LOCATIONS_QUERY
} from '../../../../../services/graphql/Queries/'
import {
    CREATE_LOCATION
} from '../../../../../services/graphql/Mutations/'

import { Error, Loading } from '../../../../util'

const { Title } = Typography
class AddLocationForm extends Component {
    state = {
        user_id: 0,
        location_name: "",
        username: "",
    }
    handleAdd = () => {
        this.setState({ ...this.state, user_id: 0, location_name: "", username: "" })
        this.props.form.resetFields()
    }
    check = (callBack) => {
        this.props.form.validateFields(err => {
            if (!err) {
                console.info('success');
                callBack()
            }
        });
    };
    render() {
        const { location_name, user_id, username } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Query query={USERS_DROPDOWN}>
                {({ loading, error, data }) => {
                    if (loading) return (
                        <Loading />
                    )
                    if (error) return <Error />
                    return (
                        <Mutation mutation={CREATE_LOCATION}
                            variables={{
                                location_name: location_name,
                                user_id: parseInt(user_id)
                            }}
                            refetchQueries={() => [{ query: LOCATIONS_QUERY }]}
                            update={(cache, { data }) => {
                                const oldLocationList = cache.readQuery({ query: LOCATIONS_QUERY }).locations
                                const newLocationList = [...oldLocationList, data.createLocation]
                                cache.writeQuery({ query: LOCATIONS_QUERY, data: { locations: newLocationList } })
                                this.handleAdd()
                            }}>
                            {(postMutation, { loading, error, value, }) => (
                                <Spin spinning={loading}>
                                    {console.log('loading: ', loading)}
                                    <div style={{ borderBottom: '1px solid #eeeeee', padding: '0px 10px' }} >
                                        <Title level={4} style={{ textAlign: 'center', marginTop: '50px', marginBottom: '40px' }}>Add Location</Title>
                                        <Form>
                                            <Form.Item label="Location: ">
                                                {getFieldDecorator('location', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please input location name',
                                                        },
                                                    ],
                                                })(<Input
                                                    prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="enter location"
                                                    value={location_name}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            ...this.state,
                                                            location_name: event.target.value
                                                        })
                                                    }}
                                                />)}
                                            </Form.Item>
                                            <Form.Item label="Username " hasFeedback>
                                                {getFieldDecorator('assignee', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please select an assignee',
                                                        },
                                                    ],
                                                })(<Select prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    value={username}
                                                    onChange={(value, key) => {
                                                        this.setState({
                                                            ...this.state,
                                                            user_id: key.key,
                                                            username: value
                                                        })
                                                    }}>
                                                    {data.users
                                                        .filter(({ permissions }) => {
                                                            return ((permissions.find(({ permission_id }) => permission_id === "0") || (permissions.find(({ permission_id }) => permission_id === "1"))))
                                                        })
                                                        .map(({ user_id, firstname, lastname }) => (
                                                            <Select.Option
                                                                key={user_id}
                                                                title={`${firstname} ${lastname}`}
                                                            >
                                                                {`${firstname} ${lastname}`}
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>)}
                                            </Form.Item>
                                            <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                                                {/* <div style={{ margin: "0px 50px" }}> */}
                                                <Button
                                                    type="primary"
                                                    onClick={() => this.check(() => postMutation())}
                                                >
                                                    Add
                                                </Button>
                                                {/* {loading && <p>Loading...</p>} */}
                                                {error && <p>Error!, Please try again</p>}
                                                {/* </div> */}
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Spin>
                            )}
                        </Mutation>
                    )
                }}
            </Query>
        )
    }
}

export default Form.create({ name: 'addLocation_form' })(AddLocationForm);

