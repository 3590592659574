const check = (form, callBack) => {
    form.validateFields(err => {
        if (!err) {
            callBack()
        }
    });
};


export {
    check
}