import React, { Component } from "react";
import { Resizable } from 'react-resizable'
import 'react-resizable/css/styles.css'
import './ResizableTable.css'

export const ResizeableTitle = (props) => {
    const { onResize, onResizeStop, onResizeStart, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />
    }

    return (
        <Resizable
            width={width}
            height={0}
            onResize={onResize}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
        >
            <th
                key='a'
                {...restProps} />
        </Resizable>
    )
}