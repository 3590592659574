import React, { Component } from 'react'
import { Form, Select, Button, Typography, Icon, Spin } from 'antd'

import { Query } from 'react-apollo';
import { Mutation } from 'react-apollo';
import {
    TICKET_SUBCATEGORY_QUERY,
    LOCATIONS_QUERY,
    USERS_QUERY,
    TICKET_ROUTES_QUERY
} from '../../../../services/graphql/Queries/'
import {
    CREATE_TICKET_ROUTE_MUTATION
} from '../../../../services/graphql/Mutations/'

import { Loading, Error } from "../../../util";

const { Title } = Typography
export default class WrappedAddRouteForm extends Component {
    state = {
        ticket_category_id: 0,
        ticket_category_name: '',
        ticket_sub_category_name: '',
        ticket_sub_category_id: null,
        username: '',
        user_id: 0,
        location: 0,
        location_name: '',
    };
    handleAdd = () => {
        this.setState({
            ...this.state, ticket_category_id: 0, ticket_category_name: '',
            ticket_sub_category_name: '', ticket_sub_category_id: null, username: '', user_id: 0, location: 0,
            location_name: ''
        })
    }
    render() {
        const { ticket_category_id, ticket_category_name, ticket_sub_category_name,
            ticket_sub_category_id, username, user_id, location, location_name } = this.state
        const { categories } = this.props
        return (
            <Query
                query={TICKET_SUBCATEGORY_QUERY}
                variables={{
                    ticket_category: parseInt(ticket_category_id),
                    is_active: 1
                }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <Loading title="fetching sub-categories..." />
                    if (error) return <Error />
                    return (
                        <Query query={LOCATIONS_QUERY}>
                            {(locationOptions) => {
                                if (locationOptions.loading) return <Loading title="Fetching Sub-Categories..." />
                                if (locationOptions.error) return <Error />
                                return (
                                    <Query query={USERS_QUERY}>
                                        {(userOptions) => {
                                            if (userOptions.loading) return <Loading title="Fetching Users..." />
                                            if (userOptions.error) return <Error />
                                            return (
                                                <Mutation
                                                    mutation={CREATE_TICKET_ROUTE_MUTATION}
                                                    update={(cache, { data }) => {
                                                        console.log('data: ', data);
                                                        const oldRouteList = cache.readQuery({ query: TICKET_ROUTES_QUERY, variables: { route_disabled: 0 } }).ticketRoutes
                                                        console.log('oldRouteList: ', oldRouteList);
                                                        const newRouteList = [{
                                                            ...data.createTicketRoute,
                                                            ticket_route_sub_category: ticket_sub_category_id ? {
                                                                ticket_sub_category_id: ticket_sub_category_id,
                                                                ticket_sub_category_name: ticket_sub_category_name,
                                                                __typename: "TicketSubCategory"
                                                            } : null
                                                        }, ...oldRouteList]
                                                        cache.writeQuery({ query: TICKET_ROUTES_QUERY, variables: { route_disabled: 0 }, data: { ticketRoutes: newRouteList } })

                                                    }}
                                                    onCompleted={(res) => {
                                                        this.handleAdd()
                                                    }}
                                                    refetchQueries={() => [{ query: TICKET_ROUTES_QUERY, variables: { route_disabled: 0 } }]}
                                                >
                                                    {(postMutation, createTicketOptions) => (
                                                        <Spin spinning={createTicketOptions.loading}>
                                                            <div style={{ padding: 15 }}>
                                                                <Title level={4} style={{ textAlign: 'center', margin: '20px' }}>Add Routes</Title>
                                                                <Form>
                                                                    <Form.Item label="Category ">
                                                                        <Select
                                                                            showSearch
                                                                            value={ticket_category_name}
                                                                            onChange={(value, key) => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    ticket_category_name: value,
                                                                                    ticket_category_id: parseInt(key.key)
                                                                                })
                                                                            }}>
                                                                            <Select.Option
                                                                                key={-1}
                                                                                value="All Categories"
                                                                            >
                                                                                All Categories
                                                                            </Select.Option>
                                                                            {categories.map(category => (
                                                                                <Select.Option
                                                                                    key={category.ticket_category_id}
                                                                                    value={category.ticket_category_name}
                                                                                >
                                                                                    {category.ticket_category_name}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                    {data.ticketSubCategories.length > 0 && <Form.Item label="Sub-Category ">
                                                                        <Select
                                                                            showSearch
                                                                            value={ticket_sub_category_name}
                                                                            onChange={(value, key) => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    ticket_sub_category_name: value,
                                                                                    ticket_sub_category_id: parseInt(key.key)
                                                                                })

                                                                            }}>
                                                                            {data.ticketSubCategories.map(subcategory => (
                                                                                <Select.Option key={subcategory.ticket_sub_category_id}
                                                                                    value={subcategory.ticket_sub_category_name}>{subcategory.ticket_sub_category_name}</Select.Option>
                                                                            ))}

                                                                        </Select>
                                                                    </Form.Item>}
                                                                    <Form.Item label="Location ">
                                                                        <Select
                                                                            showSearch
                                                                            value={location_name}
                                                                            onChange={(value, key) => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    location: parseInt(key.key),
                                                                                    location_name: value
                                                                                })

                                                                            }}>
                                                                            <Select.Option
                                                                                key={-1}
                                                                                value={"All Locations"}
                                                                            >
                                                                                All Locations
                                                                            </Select.Option>
                                                                            {locationOptions.data.locations.map(location => (
                                                                                <Select.Option
                                                                                    key={location.location_id}
                                                                                    value={location.location_name}
                                                                                >
                                                                                    {location.location_name}
                                                                                </Select.Option>
                                                                            ))}

                                                                        </Select>
                                                                    </Form.Item>

                                                                    <Form.Item label="Username ">
                                                                        <Select prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                                            value={username}
                                                                            onChange={(value, key) => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    user_id: parseInt(value),
                                                                                    username: key.props.title
                                                                                })
                                                                            }}>
                                                                            {userOptions.data.users.map(({ user_id, firstname, lastname }) => {
                                                                                return (
                                                                                    <Select.Option key={user_id} title={`${firstname} ${lastname}`}>{`${firstname} ${lastname}`}</Select.Option>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item>
                                                                        <Button
                                                                            block
                                                                            type="primary"
                                                                            onClick={() => {
                                                                                console.log(location, ticket_category_id);
                                                                                if (location === -1 && ticket_category_id === -1) {
                                                                                    locationOptions.data.locations.map(({ location_id }) => {
                                                                                        categories.map(({ ticket_category_id }) => {
                                                                                            postMutation({
                                                                                                variables: {
                                                                                                    ticket_route_location: parseInt(location_id),
                                                                                                    ticket_route_category: parseInt(ticket_category_id),
                                                                                                    ticket_route_user: user_id,
                                                                                                    route_disabled: 0
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                }
                                                                                else if (ticket_sub_category_id) {
                                                                                    postMutation({
                                                                                        variables: {
                                                                                            ticket_route_location: location,
                                                                                            ticket_route_category: ticket_category_id,
                                                                                            ticket_route_user: user_id,
                                                                                            ticket_route_sub_category: ticket_sub_category_id,
                                                                                            route_disabled: 0
                                                                                        }
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    postMutation({
                                                                                        variables: {
                                                                                            ticket_route_location: location,
                                                                                            ticket_route_category: ticket_category_id,
                                                                                            ticket_route_user: user_id,
                                                                                            route_disabled: 0
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            Add
                                                                    </Button>
                                                                        {createTicketOptions.loading && <p>Loading...</p>}
                                                                        {createTicketOptions.error && <p>Error :( Please try again</p>}
                                                                    </Form.Item>

                                                                </Form>
                                                            </div>
                                                        </Spin>
                                                    )}
                                                </Mutation>
                                            )
                                        }}
                                    </Query>
                                )
                            }}
                        </Query>
                    )
                }}
            </Query>
        )
    }
}

