import gql from "graphql-tag";

export const CREATE_TICKET_CATEGORY_MUTATION = gql`
    mutation createTicketCategory($ticket_category_name:String,$is_active:Int){
        createTicketCategory(
            ticket_category:
            {
                ticket_category_name:$ticket_category_name,
                is_active:$is_active
            }
        )
        {
            ticket_category_id
            ticket_category_name
            is_active
            ticket_sub_categories{
                ticket_category_id:ticket_sub_category_id,
                ticket_category_name:ticket_sub_category_name,
                is_active:is_active
            }
        }
    }
`;

export const CREATE_TICKET_SUBCATEGORY_MUTATION = gql`
    mutation createTicketSubCategory(
        $ticket_sub_category_name:String!,
        $ticket_category:Int!,
        $is_active:Int!
    ){
        createTicketSubCategory(
            ticket_sub_category:{
                ticket_sub_category_name:$ticket_sub_category_name,
                ticket_category:$ticket_category,
                is_active:$is_active
            }
        )
        {
            ticket_sub_category_id
            ticket_sub_category_name
            ticket_category
            is_active
        }
    }
`;

export const UPDATE_TICKET_CATEGORY = gql`
    mutation updateTicketCat($ticket_category_id:ID!, $ticket_category_name:String, $is_active:Int){
        updateTicketCategory(
        ticket_category_id:$ticket_category_id,
        ticket_category:{
            ticket_category_name:$ticket_category_name,
            is_active:$is_active
        }
        ){
            ticket_category_id
            ticket_category_name
            is_active
            ticket_sub_categories{
                ticket_category_id:ticket_sub_category_id,
                ticket_category_name:ticket_sub_category_name,
                is_active:is_active
            }
        }
    }
`;

export const UPDATE_TICKET_SUBCATEGORY = gql`
    mutation updateTicketSubCategory($ticket_sub_category_id:ID!, $ticket_sub_category_name:String, $is_active:Int){
        updateTicketSubCategory(
        ticket_sub_category_id: $ticket_sub_category_id,
        ticket_sub_category:{
            ticket_sub_category_name: $ticket_sub_category_name,
            is_active:$is_active
        }
        ){
            ticket_category_id:ticket_sub_category_id,
            ticket_category_name:ticket_sub_category_name,
            is_active:is_active,
            ticket_category
        }
    }
`;

export const CREATE_TICKET_ROUTE_MUTATION = gql`
    mutation createTicketRoute(
        $ticket_route_location:Int!,
        $ticket_route_category:Int!,
        $ticket_route_sub_category:Int,
        $ticket_route_user:Int!,
        $route_disabled:Int!
    ){
        createTicketRoute(
            ticket_route:{
                ticket_route_location:$ticket_route_location,
                ticket_route_category:$ticket_route_category,
                ticket_route_sub_category:$ticket_route_sub_category,
                ticket_route_user:$ticket_route_user,
                route_disabled:$route_disabled
            }
        )
        {
            ticket_route_id
            ticket_route_location{
                location_id
                location_name
            }
            ticket_route_category{
                ticket_category_id
                ticket_category_name
            }
            ticket_route_user{
                user_id
                firstname
                lastname
            }
        }
    }
`;

export const UPDATE_TICKET_ROUTE_MUTATION = gql`
    mutation updateTicketRoute(
        $ticket_route_id:ID!,
        $ticket_route_user:ID
        $route_disabled:Int
    ){
        updateTicketRoute(
            ticket_route_id:$ticket_route_id,
            ticket_route:{
                ticket_route_user:$ticket_route_user,
                route_disabled:$route_disabled
            }
        ) 
    }
`;

export const UPDATE_TICKET = gql`
    mutation updateTickets(
        $ticket_id:ID!,
        $ticket_is_replied: Int,
        $ticket_is_closed:Int
    ){
        updateTickets(
        ticket_id:$ticket_id,
        ticket:{
            ticket_is_replied:$ticket_is_replied,
            ticket_is_closed:$ticket_is_closed
        }
        ){
        ticket_id
        }
    }
`;
