import React, { Component } from 'react'
import {
    Form,
    Input,
    Row,
    Col,
    Modal,
    Steps,
    Button,
    Select,
    Switch,
    Spin,
    Checkbox,
    message
} from 'antd';

import { Mutation, Query } from 'react-apollo';
import { ADD_USERS_MUTATION } from '../../../services/graphql/Mutations/';
import { USERS_QUERY, LOCATIONS_QUERY, PRICE_QUERY, PERMISSIONS_QUERY } from '../../../services/graphql/Queries/'
import { Loading, Error } from '../../util'


const { TextArea } = Input;
const { Step } = Steps;

class userPersonalDetailsForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentStep: 0,
            details: {
                firstname: "",
                lastname: "",
                designation: "",
                company: "",
                address: "",
                phone: "",
                email: "",
                password: "",
                location: 0,
                price_type: 0,
                user_id: 0,
                location_list: [],
                price_list: [],
                permissions_list: [],
                assign_user_permission: [1],
                delete_user_permission: [],
            }
        }
        this.addPermission = []
        this.deletePermission = []
    }

    hasErrors = (fieldsError) => Object.keys(fieldsError).some(field => fieldsError[field]);

    editDetail = (prop, value) => {
        this.setState({
            ...this.state,
            [prop]: value
        })
    }

    assignPermissions = (checked, key) => {
        let uniquePermissionAssign = [];
        let uniquePermissionDelete = [];
        let index = -1
        if (checked) {
            this.addPermission.push(parseInt(key))
            index = this.deletePermission.indexOf(parseInt(key));
            if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                this.deletePermission.splice(index, 1);
                uniquePermissionDelete = Array.from(new Set(this.deletePermission));
            }
            uniquePermissionAssign = Array.from(new Set(this.addPermission));

        }
        else {
            this.deletePermission.push(parseInt(key))
            index = this.addPermission.indexOf(parseInt(key));
            if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                this.addPermission.splice(index, 1);
                uniquePermissionAssign = Array.from(new Set(this.addPermission));
            }
            uniquePermissionDelete = Array.from(new Set(this.deletePermission));
        }
        this.setState({
            ...this.state, assign_user_permission: uniquePermissionAssign,
            delete_user_permission: uniquePermissionDelete
        })
        console.log(this.state.assign_user_permission,
            this.state.delete_user_permission)
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    checkEmailAvailability = (rule, value, callback) => {
        const { form, users } = this.props;
        if (value && users.filter((data) => data.email === value).length > 0)
            callback('Username already in user');
        else
            callback()
    }

    render() {
        const { form, handleCreateUserModal, steps, visible, next, prev, assignPermissions, users } = this.props
        const { getFieldDecorator } = form
        const { firstname, lastname, email, password, designation, company, phone, address, price_type, assign_user_permission, location } = this.state
        const { currentStep } = this.state
        return (
            <Mutation
                mutation={ADD_USERS_MUTATION}
                variables={{
                    firstname: firstname,
                    lastname: lastname,
                    designation: designation,
                    company: company,
                    phone: phone,
                    address: address,
                    password: password,
                    email: email,
                    verification: 4,
                    location: location ? parseInt(location) : 0,
                    price_type: price_type ? parseInt(price_type) : 0,
                    permissions: assign_user_permission ? assign_user_permission : [1],
                }}
                update={(cache, { data }) => {
                    const oldEmployeeList = cache.readQuery({ query: USERS_QUERY, variables: { verification: 4 } }).users
                    const newEmployeeList = [...oldEmployeeList, data.createUser,]
                    cache.writeQuery({ query: LOCATIONS_QUERY, variables: { verification: 4 }, data: { users: newEmployeeList } })
                    // this.handleCreateUser()
                }}
                onError={(err) => {
                    console.log('err: ', err);
                    message.error('Error: Something went wrong!')
                }}
                onCompleted={() => {
                    message.success('User successfully created!')
                    handleCreateUserModal()
                }}
                refetchQueries={() => [{ query: USERS_QUERY, variables: { verification: 4 } }]}
            >
                {(postMutation, mutationData) => (

                    <Modal
                        style={{ top: 20 }}
                        title="Create User"
                        visible={visible}
                        onOk={handleCreateUserModal}
                        onCancel={handleCreateUserModal}
                        footer={this.state.currentStep === steps.length - 1 ? [
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        currentStep: this.state.currentStep - 1
                                    })
                                }}
                            >
                                Previous
                    </Button>,
                            <Button type="primary" onClick={() => {
                                if (!mutationData.loading)
                                    postMutation()
                            }}>
                                Done
                            </Button>
                        ] : [
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        this.props.form.validateFields((err, values) => {
                                            // if (err) {
                                            //     console.log('Received values of form: ', values);
                                            // }
                                            // else {
                                            //     window.scrollTo({
                                            //         top: 0,
                                            //         behavior: "smooth"
                                            //     });
                                            this.setState({
                                                ...this.state,
                                                currentStep: this.state.currentStep + 1
                                            })
                                            // }
                                        })
                                    }}
                                >
                                    Next
                </Button>
                            ]}
                    >
                        <Spin spinning={mutationData = mutationData.loading}>
                            <Steps current={this.state.currentStep} style={{ marginBottom: 20 }}>
                                {steps.map(item => (
                                    <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                            <Form
                                // labelCol={{ span: 4, offset: 2 }}
                                // labelAlign='center'
                                wrapperCol={{ span: 24 }}
                                layout="vertical"
                            >
                                <div>

                                    <Query query={LOCATIONS_QUERY}>
                                        {({ loading, error, data }) => {
                                            if (loading) return <Loading />
                                            if (error) return <Error />
                                            return (
                                                <div style={currentStep !== 0 ? {
                                                    // position: 'absolute',
                                                    // left: '-9999px'
                                                    display: "none"
                                                } : {}}>
                                                    <Form.Item label="Name: ">
                                                        {getFieldDecorator('name', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter first name and last name!'
                                                            }],
                                                        })(
                                                            <Row gutter={[8, 8]}>
                                                                <Col span={12}>
                                                                    <Input placeholder="First Name"
                                                                        // value={firstname}
                                                                        onChange={(event) => {
                                                                            this.editDetail('firstname', event.target.value)
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Input placeholder="Last Name"
                                                                        // value={lastname}
                                                                        onChange={(event) => {
                                                                            this.editDetail('lastname', event.target.value)
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item label="Email: " hasFeedback>
                                                        {getFieldDecorator('email', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter email!'
                                                            }, {
                                                                validator: this.checkEmailAvailability,
                                                            },
                                                            ],
                                                        })(<Input
                                                            // value={email}
                                                            onChange={(event) => {
                                                                this.editDetail('email', event.target.value)
                                                            }}
                                                        />)}
                                                    </Form.Item>
                                                    <Form.Item label="Phone: ">
                                                        {getFieldDecorator('phone number', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter phone number!'
                                                            }],
                                                        })(<Input
                                                            // value={phone}
                                                            type="number" pattern="[0-9]*" inputMode="numeric"
                                                            onChange={(event) => {
                                                                this.editDetail('phone', event.target.value)
                                                            }}
                                                        />)}
                                                    </Form.Item>
                                                    <Form.Item label="Password: " hasFeedback>
                                                        {getFieldDecorator('password', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter password!'
                                                            }, {
                                                                min: 6,
                                                            }],
                                                        })(<Input.Password
                                                            // value={password}
                                                            onChange={(event) => {
                                                                this.editDetail('password', event.target.value)
                                                            }}
                                                        />)}
                                                    </Form.Item>
                                                    <Form.Item label="Confirm Password" hasFeedback>
                                                        {getFieldDecorator('confirm', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'Please confirm your password!',
                                                                },
                                                                {
                                                                    validator: this.compareToFirstPassword,
                                                                },
                                                            ],
                                                        })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                                                    </Form.Item>
                                                    <Form.Item label="Designation: ">
                                                        {getFieldDecorator('designation', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter designation!'
                                                            }],
                                                        })(<Input
                                                            // value={designation}
                                                            onChange={(event) => {
                                                                this.editDetail('designation', event.target.value)
                                                            }}
                                                        />)}
                                                    </Form.Item>
                                                    <Form.Item label="Company: ">
                                                        {getFieldDecorator('company', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter password!'
                                                            }],
                                                        })(<Input
                                                            // value={company}
                                                            onChange={(event) => {
                                                                this.editDetail('company', event.target.value)
                                                            }}
                                                        />)}
                                                    </Form.Item>
                                                    <Form.Item label="Location ">
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            optionFilterProp="children"
                                                            onChange={(value, key) => {
                                                                this.editDetail('location', key.key)
                                                            }}
                                                        >
                                                            {data.locations.map(location => (
                                                                <Select.Option key={location.location_id}
                                                                    value={location.location_id}>{location.location_name}</Select.Option>
                                                            ))}

                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Address: ">
                                                        {getFieldDecorator('address', {
                                                            rules: [{
                                                                required: true,
                                                                message: 'Please enter address!'
                                                            }],
                                                        })(<TextArea
                                                            // value={address}
                                                            autoSize={{ minRows: 2, maxRows: 6 }}
                                                            onChange={(event) => {
                                                                this.editDetail('address', event.target.value)
                                                            }}
                                                        />)}
                                                    </Form.Item>
                                                </div>
                                            )
                                        }}
                                    </Query>
                                    <div
                                        style={currentStep === 0 ? {
                                            // position: 'absolute',
                                            // left: '-9999px'
                                            display: "none"
                                        } : {}}>
                                        <Query query={PRICE_QUERY}>
                                            {(priceOptions) => {
                                                if (priceOptions.loading) return <Loading />
                                                if (priceOptions.error) return <Error />
                                                return (
                                                    <Query query={PERMISSIONS_QUERY}>
                                                        {(permissionOptions) => {
                                                            if (permissionOptions.loading) return <Loading />
                                                            if (permissionOptions.error) return <Error />
                                                            return (
                                                                <Form
                                                                    // labelCol={{ span: 6 }}
                                                                    wrapperCol={{ span: 24 }}
                                                                    layout="vertical">
                                                                    <Form.Item label="Price ">
                                                                        <Select
                                                                            onChange={(value, key) => {
                                                                                this.editDetail('price_type', key.key)
                                                                            }}
                                                                        >
                                                                            {priceOptions.data.pricetypes.map(price => (
                                                                                <Select.Option key={price.price_type_id}
                                                                                    value={price.price_name}>{price.price_name}</Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item label="Permissions">
                                                                        <Checkbox
                                                                            style={{ marginLeft: 20 }}
                                                                            onChange={(event) => {
                                                                                if (event.target.checked)
                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        assign_user_permission: [0, 1],
                                                                                        delete_user_permission: []
                                                                                    })
                                                                                else
                                                                                    this.setState({
                                                                                        ...this.state,
                                                                                        assign_user_permission: [1],
                                                                                        delete_user_permission: [0]
                                                                                    })
                                                                            }}
                                                                        >
                                                                            Super User
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                    {/* <Row type="flex" justify="center" gutter={[8, 8]}>
                                                                        {permissionOptions.data.permissions.map(permission => (
                                                                            <Col span={24 / permissionOptions.data.permissions.length}>
                                                                                <Form.Item key={permission.permission_id} label={permission.permission_name}>
                                                                                    <Switch onChange={(checked) => {
                                                                                        this.assignPermissions(checked,
                                                                                            permission.permission_id)
                                                                                    }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        ))
                                                                        }
                                                                    </Row> */}
                                                                </Form>
                                                            )
                                                        }}
                                                    </Query>

                                                )
                                            }}
                                        </Query>
                                    </div>
                                </div>
                            </Form>
                        </Spin>
                    </Modal>

                )
                }
            </Mutation>
        )
    }
}

export const UserDetails = Form.create({ name: 'user_details' })(userPersonalDetailsForm);
