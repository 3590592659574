import React, { Component } from 'react'
import CatalogBrowser from './CatalogBrowser/catalogBrowser';
import './catalog.css'
export default class Catalog extends Component {
    render() {
        return (
            
                    <CatalogBrowser />
                
        )
    }
}
