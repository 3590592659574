import React, { Component } from "react";
import { Form, Icon, Input, Button, Result, Spin, message } from "antd";
import { Mutation } from "react-apollo";
import { RESET_PASSWORD } from "../../services/graphql/Mutations/"
import { Link, Redirect } from "react-router-dom";
import { relativeTimeRounding } from "moment";


class resetPasswordForm extends Component {
  state = {
    newpassword: "",
    reenteredpassword: "",
    key: "",
    displayResult: false
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newpassword")) {
      callback("Two passwords that you have entered are inconsistent!");
    } else {
      callback();
    }
  };

  handleSubmit = (e, postMutation) => {
    e.preventDefault();
    if (this.state.newpassword.length === 0) {
      alert("Enter a valid password");
    } else {
      this.props.form.validateFields((err, values) => {
        postMutation();
        console.log("Received values of form: ", values);
      });
    }
  };
  render() {
    const keyTrial = this.props.keySearch.split("=");
    const key = keyTrial[1];
    const { getFieldDecorator } = this.props.form;
    const { newpassword, reenteredpassword, displayResult } = this.state;
    return (
      <Mutation
        mutation={RESET_PASSWORD}
        variables={{ token: key, password: newpassword }}
        onCompleted={result => {
          console.log(result);
          message.success("Password has been successfully reset")
          this.setState({
            ...this.state,
            displayResult: true
          });
        }}
        onError={err => {
          console.log("error", err)
          message.error("Sorry, something went wrong")
        }}
      >
        {(resetPasswordMutation, { loading }) => {
          return (
            <div>
              <Spin spinning={loading}>
                <Form
                  className="login-form"
                  onSubmit={e =>
                    this.handleSubmit(e, () => resetPasswordMutation())
                  }
                >
                  <Form.Item>
                    {getFieldDecorator("newpassword", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter a valid password!"
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        name="newpassword"
                        placeholder="Enter new password"
                        onChange={event => {
                          this.setState({
                            newpassword: event.target.value
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("reenterpassword", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter a valid password!"
                        },
                        {
                          validator: this.compareToFirstPassword
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        name="reenterpassword"
                        placeholder="Re-nter new password"
                        onChange={event => {
                          this.setState({
                            reenteredpassword: event.target.value
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      RESET PASSWORD
                  </Button>
                  </Form.Item>
                </Form>
                {displayResult && (
                  <Result
                    status="success"
                    title="Password has been reset successfully!"
                    subTitle="Click here to go back to the login page."
                    extra={[
                      <a href="/">
                        <Button type="primary">Back to login</Button>
                      </a>
                    ]}
                  />
                )}
              </Spin>
            </div>
          );
        }}
      </Mutation>
    );
  }
}
export const WrappedResetPasswordForm = Form.create({
  name: "reset_password"
})(resetPasswordForm);
