import gql from "graphql-tag";

export const PRICE_QUERY = gql`
    query pricetypes{
        pricetypes{
            price_type_id
            price_name
        }
    }
`;

export const LOCATIONS_QUERY = gql`
    query locations{
        locations{
            location_id
            location_name
        }
    }
`;

export const PERMISSIONS_QUERY = gql`
    query permissions{
        permissions{
            permission_id
            permission_name
            permission_friendly_name
        }
    }
`;

export const IMAGE_URL_QUERY = gql`
    query imageUrl(
        $filename:String
        $type:String
    ){
        imageURL(where: {
            filename:$filename,
            type:$type
        })
    }
`