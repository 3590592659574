import React, { Component } from 'react'
import { Tabs, Row, Col, Spin } from 'antd'
import { EditLocation } from './LocationAndUsers/editLocation';
import AddLocationForm from './LocationAndUsers/addLocation';
import AddCategory from './addCategory'
import AddSubCategory from './addSubCategory'
import EditCategory from './editCategory'

import { Query, Mutation } from 'react-apollo';

import { TICKET_CATEGORY_QUERY } from '../../../../services/graphql/Queries/'
import { Loading, Error } from '../../../util'

const { TabPane } = Tabs;

export default class LocationCategories extends Component {
  state = {
    tabPosition: '',
  };
  render() {
    return (
      <div>
        <Tabs type="card" tabPosition="top" defaultActiveKey="1"
          tabBarStyle={{ paddingLeft: '10px', marginBottom: '0px' }}>
          <TabPane tab="Manage Location" key="1">
            <Row style={{ borderBottom: '1px solid #eeeeee', }} >
              <Col span={16}>
                <EditLocation />
              </Col>
              <Col span={8}>
                <AddLocationForm />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Manage Ticket Categories" key="2" >
            <Query query={TICKET_CATEGORY_QUERY}>
              {({ loading, error, data }) => {
                console.log('data: ', data);
                if (loading) return (
                  <Loading />
                )
                if (error) {
                  console.log('category error: ', error);
                  return (
                    <Loading />
                  )
                }
                return (
                  <Row style={{ paddingLeft: "30px" }}>
                    <Col span={8} style={{
                      borderRight: '1px solid #eeeeee',
                      height: '100%'
                    }}>
                      <EditCategory categories={data.ticketCategories} />
                    </Col>
                    <Col span={8} >
                      <AddCategory />
                    </Col>
                    <Col span={8} >
                      <AddSubCategory categories={data} />
                    </Col>
                  </Row>
                )
              }}
            </Query>
          </TabPane>

        </Tabs>
      </div>
    )
  }
}
