import React, { Component } from "react";

//UI Framework
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Modal,
  Typography,
  Spin,
  message
} from "antd";

//GraphQL
import { Mutation } from "react-apollo";
import { LOGIN_USER } from "../../services/graphql/Mutations/";

//Utils
import { check } from "../../services/FormValidation";
import { WrappedForgotPasswordForm } from "./forgotPasswordForm";
import { AUTH_TOKEN, REMEMBER_ME } from '../../services/constants.js'

const { Text } = Typography;
class LoginForm extends Component {
  state = {
    modalVisible: false,
    email: "",
    password: "",
    rememberMe: true,
    regEmail: ""
  };
  handleCancel = e => {
    this.setState({ ...this.state, modalVisible: false });
  };

  setAuthorizationHeader = token => {
    localStorage.setItem(AUTH_TOKEN, token);
  };

  _saveUserData = async token => {
    await localStorage.setItem(AUTH_TOKEN, token);
    await localStorage.setItem(REMEMBER_ME, this.state.rememberMe);
  };

  _confirm = async data => {
    const { loginUser } = data;

    if (
      loginUser === "No such user." ||
      loginUser === "Wrong Username or Password"
    ) {
      localStorage.clear();
    } else {
      const verified = parseInt(loginUser.split(":")[0]);
      if (verified <= 2) {
        localStorage.clear();
      }
      else {
        const jwt = loginUser.split(":")[1];
        this._saveUserData(jwt);
      }
    }
  };

  changeState = email => {
    this.setState({
      ...this.state,
      regEmail: email
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      email,
      password,
    } = this.state;
    return (
      <Mutation
        mutation={LOGIN_USER}
        variables={{
          email,
          password
        }}
        update={(cache, { data }) => {
          this._confirm(data);
        }}
        onCompleted={data => {
          const { loginUser } = data;
          console.log("loginUser",loginUser)
          if (
            loginUser === "No such user." ||
            loginUser === "Wrong Username or Password"
          )
            message.error(loginUser)
          else {
            const verified = parseInt(loginUser.split(":")[0]);
            if (verified <= 2) {
              message.warning(`Your registration request is pending for admin approval. We will notify you once your account has been verified.\n Thank you.`);
              localStorage.clear();
            }
            else {
              this.props.history.push("/dashboard");
            }
          }
        }}
        onError={err => {
          console.log("Login error: ", err);
        }}
      >
        {(postMutation, mutationOptions) => {
          return (
            <Spin spinning={mutationOptions.loading}>
              <div>
                <WrappedForgotPasswordForm
                  modalVisible={this.state.modalVisible}
                  regEmailProp={this.state.regEmail}
                  changeState={email => {
                    this.changeState(email);
                  }}
                  handleCancel={() => {
                    this.handleCancel();
                  }}
                />
                <Form className="login-form">
                  <Form.Item>
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your email!"
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="mail"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Email"
                        onChange={event => {
                          this.setState({
                            ...this.state,
                            email: event.target.value
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Password!"
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Password"
                        onChange={event => {
                          this.setState({
                            ...this.state,
                            password: event.target.value
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("remember", {
                      valuePropName: "checked",
                      initialValue: true
                    })(
                      <Checkbox
                        value={this.state.rememberMe}
                        onChange={e => {
                          this.setState({
                            ...this.state,
                            rememberMe: !this.state.rememberMe
                          });
                        }}
                      >
                        Remember me
                      </Checkbox>
                    )}
                    {
                      <a
                        className="login-form-forgot"
                        href="#"
                        onClick={() => {
                          this.setState({ ...this.state, modalVisible: true });
                        }}
                      >
                        Forgot password
                      </a>
                    }

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={mutationOptions.loading}
                      ghost={mutationOptions.loading}
                      onClick={() =>
                        check(this.props.form, () => postMutation())
                      }
                    >
                      LOGIN
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Spin>
          );
        }}
      </Mutation>
    );
  }
}

export const WrappedLoginForm = Form.create({ name: "login_form" })(LoginForm);
