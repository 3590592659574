import gql from "graphql-tag";

export const FETCH_USER = gql`
    query profile{
        profile{
        user_id
        firstname
        lastname
        designation
        company
        phone
        address
        email
        verification
        date_created
        location{
            location_id
            location_name
        }
        price_type{
            price_type_id
            price_name
        }
        permissions{
            permission_id,
            permission_name,
            permission_friendly_name
        }
        ticket_created_at
        }
    }
`