import React, { useEffect } from "react";
import "./App.css";

//UI Framework
import { Result, Button } from "antd";

//Pages
import { LoginPage } from "./components/LoginPage/loginPage";
import MainPage from "./components/MainPage/mainPage";
import ResetPassword from "./components/LoginPage/resetPasswordPage";
import { AuthRoute } from "./components/util/AuthRoute";
import { Route, Switch, useLocation } from "react-router-dom";

// GraphQL Import
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";

//Transition
import { AnimatePresence } from "framer-motion";

//constants
import { AUTH_TOKEN, REMEMBER_ME } from "./services/constants.js";

const httpLink = createHttpLink({
  uri: "http://43.204.127.226:4000/",

  // uri: "http://13.234.5.70:4000/"
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage[AUTH_TOKEN];
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (
      localStorage[REMEMBER_ME] === "false" ||
      typeof localStorage[REMEMBER_ME] === "undefined"
    ) {
      localStorage.clear();
    }
  }, []);

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route path="/" exact component={LoginPage} />
            <Route path="/reset/password/" component={ResetPassword} />
            <AuthRoute exact path="/dashboard" component={MainPage} />
            <Route
              path="*"
              exact
              component={() => (
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={
                    <Button
                      type="primary"
                      onClick={() => (window.location.href = "/")}
                    >
                      Back Home
                    </Button>
                  }
                />
              )}
            />
          </Switch>
        </AnimatePresence>
      </ApolloProvider>
    </div>
  );
};
